import cm from '../controllers';

const authGuard = async () => {
  if (cm.controllers.auth.isLoggedIn) {
    const groups = (cm.controllers.auth.user.signInUserSession.idToken.payload['cognito:groups']);
    if(!groups || groups.indexOf('admin') === -1) {
        return {
          redirect: '/unauthorized'
        }
      } else {
        return true;
      }
  } else {
    return {
      redirect: '/auth'
    }
  }
}


export default authGuard;