import { Badge, List } from 'antd';
import { omit } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';

import cm from '../../../controllers';
import Widget from '../../dashboard/widget';
import SegnalazioniTable from '../table';

const SingleType = ({item, openDrawer}) => {
  return <button
    style={{
      background: 'transparent',
      border: 0,
      display :'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      cursor: 'pointer'
    }}
    onClick={() => {
      openDrawer(
        `Elenco segnalazioni codice ${item.text}`,
        SegnalazioniTable,
        {
          ids: item.items.map(i => i.id)
        }
      )
    }}
  >
    <span>{item.text}</span>
    <Badge count={item.items.length} showZero style={{
      background: item.background,
      color: item.color
    }} />
  </button>
};

const ByType = (props) => {
  return <Widget {...props}>
    <List 
    renderItem={item => <List.Item>
      <SingleType item={item} openDrawer={props.openDrawer} />
    </List.Item>}
    dataSource={[
     {
       color: 'white',
       background: 'rgb(219, 58, 52)',
       text: 'Rosso',
       items: cm.controllers.segnalazioni.list.filter(item => !item.resolved).filter(item => item.codice === 'rosso')
     },
     {
        color: '#333',
        background: 'rgb(255, 200, 87)',
        text: 'Giallo',
        items: cm.controllers.segnalazioni.list.filter(item => !item.resolved).filter(item => item.codice === 'giallo')
      },
      {
        color: 'white',
        background: 'rgb(61, 165, 217)',
        text: 'Blu',
        items: cm.controllers.segnalazioni.list.filter(item => !item.resolved).filter(item => item.codice === 'blu')
      },
      {
        color: '#333',
        background: 'rgb(240, 240, 240)',
        text: 'Bianco',
        items: cm.controllers.segnalazioni.list.filter(item => !item.resolved).filter(item => item.codice === 'bianco')
      } 
    ]}
  /> 
  </Widget>
};

export default observer(ByType);