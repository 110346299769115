import { CloseOutlined, LoadingOutlined, UserAddOutlined } from '@ant-design/icons';
import {Button, Drawer, List, Space, Typography, Modal, Table, Tag} from 'antd';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import cm from '../../controllers';
import { TEAM_COLORS, TEAM_TYPE_ARRAY } from '../../controllers/Teams';

const Status = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: ${props => props.online ? '#4E9F15': '#B31317'};
`;

const SingleMember = ({member, channel, onRemove}) => {
  const [descriptor, setDescriptor] = useState(null)

  useEffect(() => {
    const load = async () => {
      const r = await member.getUserDescriptor();
      setDescriptor(r);
    }
    load();
  }, [member]);
  
  return descriptor
    ? <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%'
    }}>
        <Space>
          <Status online={descriptor.online} /><span>{descriptor.identity}</span>
        </Space>
        <Button style={{
          display: channel !== 'general' && 
            member.identity !== cm.controllers.chat.username 
              ? 'inline-block' 
              : 'none'
          }} icon={<CloseOutlined />} onClick={async () => {
            await cm.controllers.chat.removeMember(channel, member.identity);
            if (onRemove) onRemove();
          }} />  
      </div>
    : <LoadingOutlined />
};

const UserTable = observer(({visible, channel, current, onClose, onAdd}) => {
  const [users, setUsers] = useState(null);
  const [selected, setSelected] = useState([]);

  const columns = useMemo(() => [{
    title: 'Nome',
    dataIndex: 'nome'
  }, {
    title: 'Cognome',
    dataIndex: 'cognome'
  }, {
    title: 'Codice',
    dataIndex: 'codice'
  }, {
    title: 'Tipo',
    dataIndex: 'tipo',
    render: item => <Tag style={{
      ...TEAM_COLORS[TEAM_TYPE_ARRAY[item]]
    }}>{TEAM_TYPE_ARRAY[item]}</Tag>
  }], [])

  useEffect(() => {
    let r;
    reaction(
      () => cm.controllers.teams.list,
      async (list, reaction) => {
        r=reaction;
        const equip = cm.controllers.teams.list
          .slice()
          .map(item => item.equipaggio.map(user => ({
            user,
            codice: item.codice,
            tipo: item.tipo
          })))
          .reduce((arr, current) => arr.concat(current), [])


        for (let i = 0; i < equip.length; i++) {
          const user = await cm.controllers.cts.get(equip[i].user);

          equip[i] = {
            ...equip[i],
            ...user
          };
        }

        setUsers(equip);
      }, {fireImmediately: true}
    );
    return () => {
      if (r) r.dispose()
    };
  }, [])



  return <Modal title="Seleziona utenti" width={800} visible={visible} onClose={onClose} onCancel={onClose} onOk={async () => {
    for (let i = 0; i < selected.length; i++) {
      const sel = selected[i];
      await cm.controllers.chat.addToChannel(channel, sel)
    }
    if (onAdd) onAdd();
    if (onClose) onClose();
  }}>
    <Table 
      dataSource={users}
      columns={columns}
      loading={!users}
      rowKey={item => item.cognitoRef}
      rowSelection={{
        type: 'checkbox',
        onChange: (result, selected) => {
          setSelected(selected.map(item => `${item.nome} ${item.cognome}`));
        },
        getCheckboxProps: record => ({
          disabled: current.indexOf(`${record.nome} ${record.cognome}`) !== -1
        }),
      
      }}
    />
    </Modal>
});

const useUserManagement = () => {
  const [visible, setVisible] = useState(false);
  const [channel, setChannel] = useState(null);
  const [members, setMembers] = useState(null);
  const [modal, setModal] = useState(false);

  
  const show = useCallback(async (channel) => {
    setChannel(channel);
    setVisible(true);
  }, []);
  
  const close = useCallback(() => {
    setVisible(false)
    setChannel(null);
  }); 
  
  const toggle = useCallback(async (channel) => {
    if (visible) close()
    else await show(channel)
  })

  const onAdd = useCallback(async() => {
    const m = await cm.controllers.chat.getChannelMembers(channel);
    setMembers(m);
  })
  

  useEffect(() => {
    const load = async () => {
      const m = await cm.controllers.chat.getChannelMembers(channel);
      setMembers(m);
    }
    if (channel) {
      load();
    }
  }, [channel]);

  const CustomDrawer = (props) => <Drawer
    {...props}
    visible={visible}
    getContainer={false}
    onClose={close}
    style={{
      position: "absolute"
    }}
  > {
    visible ? <>
 <Typography.Title level={3}>Utenti</Typography.Title>
    {
      !members
        ? <LoadingOutlined />
        : <List 
          dataSource={members}
          renderItem={(item) => <List.Item>
            <SingleMember member={item} channel={channel} onRemove={onAdd}/>
          </List.Item>} 
        />
    }
    {
      channel !== 'general' 
        ? <Button icon={<UserAddOutlined />} onClick={() => setModal(true)}>Aggiungi utente</Button>
        : null

    }
    <UserTable onAdd={onAdd} channel={channel} onClose={() => setModal(false)} visible={modal} current={(members || []).map(item => item.identity)} />
    </>: null
  }
   
  </Drawer>;

  return [CustomDrawer, {show, close, toggle}];
};

export default useUserManagement;