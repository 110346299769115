import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Descriptions, List, Space, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import Maps from 'google-map-react';
import styled from 'styled-components';

import cm from '../../../controllers';
import { TEAM_COLORS, TEAM_TYPE_ARRAY } from '../../../controllers/Teams';
import useDrawer from '../../../hooks/useDrawer';
import UserDetail from '../../cts/detail';
import CarMarker from '../../car-marker';
import { SEGNALAZIONI_COLORS } from '../../../controllers/Segnalazioni';
import SegnalazioniDetail from '../../segnalazioni/detail';

const Assegnazione = ({id, openDrawer}) => {
  const [segnalazione, setSegnalazione] = useState(null);

  useEffect(() => {
    const load = async () => {
      const res = await cm.controllers.segnalazioni.get(id);
      setSegnalazione(res);
    };  
    setSegnalazione(null);
    load();
  }, [id]);

  return !segnalazione
    ? <LoadingOutlined />
    : <Space>Codice 
      <Tag
        style={{...SEGNALAZIONI_COLORS[segnalazione.codice]}}
      >{segnalazione.codice.charAt(0).toUpperCase()}{segnalazione.codice.slice(1)}</Tag>
      <Button icon={<InfoCircleOutlined />} onClick={() => openDrawer(
        'Dettagli segnalazione',
        SegnalazioniDetail,
        {id: segnalazione.id}
      )}></Button>
    </Space>
}
 
const TeamDetail = ({id, close}) => {
  const [team, setTeam] = useState(null);
  const [Drawer, open] = useDrawer();

  useEffect(() => {
    const load = async () => {
      const result = await cm.controllers.teams.get(id);
      
      for (let i = 0; i < result.equipaggio.length; i++) {
        if (typeof result.equipaggio[i] === 'string'){
          result.equipaggio[i] = await cm.controllers.cts.get(result.equipaggio[i])
        }
      }
      setTeam(result);
    };
    load();
  }, [id])

  return !team 
    ? <LoadingOutlined />
    :  <><Descriptions bordered column={2}>
    <Descriptions.Item label="Codice">{team.codice}</Descriptions.Item>
    <Descriptions.Item label="Tipo">
      <Tag style={{...TEAM_COLORS[TEAM_TYPE_ARRAY[team.tipo]]}}>
        {TEAM_TYPE_ARRAY[team.tipo]}
      </Tag>
    </Descriptions.Item>
    <Descriptions.Item label="Equipaggio">
      <List 
        dataSource={team.equipaggio}
        renderItem={(item) => <List.Item>
          <Space size={20}>
            <span>{item.nome} {item.cognome}</span> 
            <Button onClick={() => {
              open(
                `Dettagli utente ${item.nome} ${item.cognome}`,
                UserDetail,
                {id: item.cognitoRef}  
              )
            }} icon={<InfoCircleOutlined />}></Button>
          </Space>
        </List.Item>}
      />
    </Descriptions.Item>
    { 
      team.ultimaPosizione
      ? <Descriptions.Item label="Ultima posizione">
        <Space direction="vertical">
          <span>latitudine: {team.ultimaPosizione.latitude}</span>
          <span>longitudine: {team.ultimaPosizione.longitude}</span>
        </Space>
        </Descriptions.Item>
      : null
    }
    {
      team.assegnatoA && team.assegnatoA !== "null"
        ? <Descriptions.Item label="Assegnato a ">
          <Assegnazione id={team.assegnatoA} openDrawer={open}/>
        </Descriptions.Item>
        : null
    }
  </Descriptions>
  {team.ultimaPosizione
  ? <div style={{
    height: '300px'
  }}>
      <Maps
        bootstrapURLKeys={{ key: 'AIzaSyDtTtWDz9AynV0hBW2_WVZqfKOaoHpxrsc' }}
        defaultCenter={{
          lng: team.ultimaPosizione.longitude,
          lat: team.ultimaPosizione.latitude
        }}
        defaultZoom={11}
      >
        <CarMarker tipo={team.tipo} codice={team.codice} lat={team.ultimaPosizione.latitude} lng={team.ultimaPosizione.longitude} />
      </Maps>
    </div>
    : null
  }
  <footer style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '20px'
      }}>
        <small>Creato il {new Date(team.createdAt).toLocaleString()}</small>
        <small>Ultimo aggiornamento il {new Date(team.updatedAt).toLocaleString()}</small>
      </footer>
  <Drawer close={close}/>
  </>
};

export default TeamDetail; 