import { AlertOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Statistic } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import cm from '../../../controllers';

import Widget from '../../dashboard/widget';
import SegnalazioniTable from '../table';

export const Totali = observer(({openDrawer}) => {
  return <Widget style={{cursor: 'pointer'}} onClick={() => openDrawer(
    'Tutte le Segnalazioni',
    SegnalazioniTable,
    {ids: cm.controllers.segnalazioni.list.map(i => i.id)}
  )}>
    <Statistic 
      title="Totale segnalazioni"
      value={
        cm.controllers.segnalazioni.list.length
      }
      prefix={<AlertOutlined />}
      suffix={cm.controllers.segnalazioni.list.length === 1 ? 'segnalazione' : 'segnalazioni'}
    />
  </Widget>
});

export const InCorso = observer(({openDrawer}) => {
  return <Widget style={{cursor: 'pointer'}} onClick={() => openDrawer(
    'Segnalazioni in corso',
    SegnalazioniTable,
    {ids: cm.controllers.segnalazioni.inCorso.map(i => i.id)}
  )}>
    <Statistic 
      title="Segnalazioni in corso"
      value={cm.controllers.segnalazioni.inCorso.length}
      valueStyle={{ color: '#e32828' }}
      prefix={<CloseCircleOutlined />}
      suffix={cm.controllers.segnalazioni.inCorso.length === 1 ? 'segnalazione' : 'segnalazioni'}
    />
  </Widget>
});



export const Risolte = observer(({openDrawer}) => {
  return <Widget style={{cursor: 'pointer'}} onClick={() => openDrawer(
    'Segnalazioni Risolte',
    SegnalazioniTable,
    {ids: cm.controllers.segnalazioni.risolte.map(i => i.id)}
  )}>
    <Statistic 
      valueStyle={{ color: '#3f8600' }}
      title="Segnalazioni risolte"
      value={cm.controllers.segnalazioni.risolte.length}
      prefix={<CheckCircleOutlined />}
      suffix={cm.controllers.segnalazioni.risolte.length === 1 ? 'segnalazione' : 'segnalazioni'}
    />
  </Widget>
});

