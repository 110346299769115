import  React from 'react';
import {Form, Input, Button, message} from 'antd';

import cm from '../../../controllers';
import { navigate } from '@reach/router';


const NewPasswordRequired = () => {
  const [form] = Form.useForm();
  return (<>
    <h2>Cambia la password temporanea</h2>
    <p>Serve modificare la password preimpostata per continuare</p>
    <p>
      La password deve:
    </p>
    <ul>
      <li>avere almeno 8 caratteri</li>
      <li>contenere una maiuscola</li>
      <li>contenere una minuscola</li>
      <li>contenere un numero</li>
    </ul>
    <Form
      form={form}
      labelCol={{lg: 24}}
      labelAlign="left"
      wrapperCol={{lg: 24}}
      onFinish={async (values) => {
        try {
          await cm.controllers.auth.completeNewPassword(values.password);
        } catch(e) {
          navigate('/auth/login')
        }
      }}
    >
      <Form.Item
        name="password"
        label="Password"
        hasFeedback
        dependencies={['password']}
        rules={[
          {required: true, message: 'Campo necessario'},
          () => ({
            validator (rule, value) {
              if (/^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9]).{8,})\S$/.test(value)) {
                return Promise.resolve()
              }         
              return Promise.reject('La password non soddisfa i requisiti');
            }
          })
        ]}
      > 
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="confirm"
        label="Conferma la password"
        hasFeedback
        rules={[
          {required: true, message: 'Campo necessario'},
          ({getFieldValue}) => ({
            validator (rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject('Le due password non coincidono');
            }
          })
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item style={{textAlign: 'right'}}>
        <Button type="primary" htmlType="submit">Invia</Button>
      </Form.Item>
    </Form>
  </>
  );
}

export default NewPasswordRequired;