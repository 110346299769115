import { ApiOutlined, BookOutlined } from '@ant-design/icons';
import { Button, message, Modal, Space, Tag, Typography } from 'antd';
import moment from 'moment';
import React, { useCallback, useState } from 'react';

import cm from '../../controllers';

const Attivazione = ({}) => {
  const [activating, setActivating] = useState(false);
  const [deactivating, setDeactivating] = useState(false);

  const activate = useCallback(() => {
    Modal.confirm({
      cancelText: 'Annulla',
      okText: 'Attiva',
      okType: 'primary',
      title: 'Sei sicuro?',
      content: <p>Sei sicuro di voler attivitare questo evento. L'attivazione di questo evento comporterà l'invio delle credenziali a tutti i membri dell'equipaggio</p>,
      onCancel: () => {
        setActivating(true);
      },
      onOk: async () => {
        try {
          setActivating(true);
          await cm.controllers.eventi.activate(cm.controllers.eventi.current.id);
        } catch (e) {
          message.error('Errore durante l\'attivazione dell\'evento');
        } finally {
          setActivating(false);
        }
      }
    });
  });

  const disable = useCallback(() => {
    Modal.confirm({
      cancelText: 'Annulla',
      okText: 'Disattiva',
      okType: 'danger',
      title: 'Sei sicuro?',
      content: <p>Sei sicuro di voler disattivare questo evento. La disattivazione di questo evento disabiliterà temporaneamente gli account dell'equipaggio fino alla riattivazione</p>,
      onCancel: () => {
        setDeactivating(true);
      },
      onOk: async () => {
        try {
          setDeactivating(true);
          await cm.controllers.eventi.disable(cm.controllers.eventi.current.id);
        } catch (e) {
          message.error('Errore durante l\'attivazione dell\'evento');
        } finally {
          setDeactivating(false);
        }
      }
    });
  });

  return cm.controllers.eventi.current 
    ? <>
      <Typography.Title level={5}>
        Al momento questo evento {
          cm.controllers.eventi.current.enabled
            ? <Tag style={{
              background: '#62B519',
              color: '#fff'
            }}>
              risulta attivato
            </Tag>
            : <Tag style={{
              background: '#DB2E30',
              color: '#fff'
            }}>
              non risulta attivato
            </Tag>
        }
      </Typography.Title> 
      {
        cm.controllers.eventi.current.enabled 
          ? <p>Attiva l'evento per abilitare gli account dell'equipaggio dei team ed inviare ad ogni utente le proprie credenziali</p>
          : <p>Disattiva l'evento per disabilitare tutti gli account utente.</p>
      }
      <Space>
        <Button 
          type="primary" 
          danger={cm.controllers.eventi.current.enabled} 
          icon={ <ApiOutlined />} 
          onClick={
            cm.controllers.eventi.current.enabled
              ? disable
              : activate
          } 
        >
          {
            cm.controllers.eventi.current.enabled
            ? 'Disattiva ora'
            : 'Attiva ora'
          }
        </Button>
        <Button icon={<BookOutlined />} disabled={moment().isBefore(moment(cm.controllers.eventi.current.endDate).add(1, 'day') , 'day')}>
          Archivia
        </Button>
        { 
          moment().isBefore(moment(cm.controllers.eventi.current.endDate).add(1, 'day') , 'day')
            ? <small>Puoi archiviare un evento una volta terminato</small>
            : null
        }
      </Space>
    </>
    : null
};

export default Attivazione;
