import Controller from '../utils/Controller';
import { decorate, computed, action, observable, reaction } from 'mobx';

export const TEAM_TYPE = {
  "Apripista": 0,
  "Pace Car": 1,
  "Scopa": 2,
  "Technical car": 3,
  "Medical car": 4,
  "Staff": 5
};
export const TEAM_COLORS = {
  "Apripista": {background: '#D9A0A2', color: '#333'},
  "Pace Car": {background: '#133b6b', color: 'white'},
  "Scopa": {background: '#575657', color: 'white'},
  "Technical car": {background:'#fe7d07', color: '#333'},
  "Medical car": {background: '#dc1313', color: 'white'},
  "Staff": {background: '#988029', color: 'white'}
}

export const TEAM_TYPE_ARRAY = ['Apripista', 'Pace Car', 'Scopa', 'Technical car', 'Medical car', 'Staff']

class Teams extends Controller {
  constructor(options, manager) {
    super(options, manager);
    this.fetched = false;
    this.list = [];
  }
 
  get map() {
    return this.list
      .reduce((agg, curr) => {
        agg[curr.id] = curr;
        return agg;
      }, {});
  }

  get name() {
    return 'teams'
  }

  get free () {
    return this.list.filter(item => !item.assegnatoA);
  }

  get priority() {
    return 1002;
  }

  async init() {
    super.init();
    reaction(
      () => this.manager.controllers.eventi.current,
      () => {
        this.list = [];
        this.fetched = false;
      }
    );
    this.manager.controllers.websocket.addEventListener('team_updated', this.update_team.bind(this));
    this.manager.controllers.websocket.addEventListener('team_assigned', this.onAssigned.bind(this));
    this.manager.controllers.websocket.addEventListener('team_released', this.onReleased.bind(this));
  }

  onAssigned(body) {
    const copy = this.list.slice();
    const idx = copy.findIndex((item) => item.id === body.team);
    if (idx !== -1) {
      copy[idx] = {
        ...copy[idx],
        assegnatoA: body.segnalazione
      }
      this.list = copy;
    }
  } 

  onReleased(body) {
    const copy = this.list.slice();
    const idx = copy.findIndex((item) => body === item.id);
    if (idx !== -1) {
      copy[idx] = {
        ...copy[idx],
        assegnatoA: null
      };
      this.list = copy;
      this.manager.controllers.segnalazioni.setCurrent = null;
    }
  }

  update_team(body) {
    this.replace(body.id, body)
  }

  async getByAssignement(id) {
    const currentEvent = this.manager.controllers.eventi.current;
    if (currentEvent) {
      const fromCache = this.list.filter(item => item.assegnatoA === id);
      if (fromCache.length > 0) {
        return fromCache;
      } else {
        const result = await this.manager.controllers.api.get(`/eventi/${currentEvent.id}/teams?segnalazione=${id}`)
        this.list = [...this.list, ...result.Items]
      }
    }
  }

  async release(id) {
    const currentEvent = this.manager.controllers.eventi.current;
    if (currentEvent) {
      const result = await this.manager.controllers.api.post(`/eventi/${currentEvent.id}/teams/${id}/release`);
      this.replace(id, result.Item)
    }
  }

  async assign(team, segnalazione) {
    const currentEvent = this.manager.controllers.eventi.current;
    if (currentEvent) {
      const result = await this.manager.controllers.api.post(`/eventi/${currentEvent.id}/teams/${team}/assign`, {
        body: {
          segnalazione
        }
      });
      this.replace(team, result.Item)
    }
  }

  async get(id) {
    if (this.map[id]) {
      return this.map[id];
    } else {
      const currentEvent = this.manager.controllers.eventi.current;
      if (currentEvent) {
        const result = await this.manager.controllers.api.get(`/eventi/${currentEvent.id}/teams/${id}`);
        if (result.Item) {
          this.list = [...this.list, result.Item];
          return result.Item;
        } else {
          return undefined;
        }
      }
    }
  }

  async delete(id) {
    const currentEvent = this.manager.controllers.eventi.current;
    if (currentEvent) {
      const result = await this.manager.controllers.api.del(`/eventi/${currentEvent.id}/teams/${id}`) 
      this.list = this.list.filter(item => item.id !== result.Item.id);
    }
  }

  async fetch() {
    const currentEvent = this.manager.controllers.eventi.current;
    if (currentEvent) {
      const result = await this.manager.controllers.api.get(`/eventi/${currentEvent.id}/teams`) 
      this.list = result.Items;
      this.fetched = true;
    }
  }

  async create(values) {
    const currentEvent = this.manager.controllers.eventi.current;
    if (currentEvent) {
      const result = await this.manager.controllers.api.post(`/eventi/${currentEvent.id}/teams`, {
        body: values
      });
      if (result) {
        this.list = [...this.list, result.Item]
      }
    }
  }

  async update(id, values) {
    const currentEvent = this.manager.controllers.eventi.current;
    if (currentEvent) {
      const result = await this.manager.controllers.api.put(`/eventi/${currentEvent.id}/teams/${id}`, {
        body: values
      });
      this.replace(id, result.Item)
    }
  }



  find(id) {
    return this.list.findIndex(tappa => tappa.id === id);
  }

  replace(id, valore) {
    const temp = [...this.list];
    const idx = this.find(id);
    if (idx !== -1) {
      temp[idx] = valore;
      this.list = temp;
    }
  }
  
}

export default decorate(Teams, {
  list: observable,
  map:computed,
  free: computed,
  fetch: action,
  delete: action,
  update: action,
  creaete: action
});