import React, { useCallback } from 'react';
import { Upload, Progress, Button } from 'antd';
import styled from 'styled-components';
import { LoadingOutlined, CloudUploadOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import { Storage } from 'aws-amplify';

const UploadButtonContainer = styled.div`
  width: 150px;
  height: 150px;
  border: 3px dashed #ececec;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  cursor:pointer;
  position: relative;
  img  {
    width: 100%;
    height: 100%;
    object-fit: cover
  }
`;
const ProgressContainer = styled.div`
  position: absolute;
  top:0 ;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 10px;
  background: rgba(0,0,0,0.4);
`;
const DeleteOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.5);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 300ms linear;
  img {
    fit-object: cover;
  }
`;
const ImageContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  &:hover {
    ${DeleteOverlay} {
      opacity: 1;
    }
  }
`;


const imgToBase64 = (img) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => {
    if (reader.result && typeof reader.result === 'string')
      resolve(reader.result);
    else reject(new Error('invalid result'))
  });
  reader.readAsDataURL(img);
});

const UploadInput = React.forwardRef((props, ref) => {
  const [state, setState] = React.useState({
    loading: false, 
    imageUrl: null
  });

  React.useEffect(() => { 
    if (props.value) {
      const getUrl = async (key) => {
        const url = await Storage.get(key);
        setState({
          loading: false,
          imageUrl: url
        })
      }; 
      if (typeof props.value === 'string') {
        getUrl(props.value);
      }
    }
  }, [props.value])

  const handleChange = useCallback((info) => {
    if (!info.file) return;
    if (props.onChange) props.onChange(info.file);
    imgToBase64(info.file)
      .then((result) => {
        setState({
          loading: false,
          imageUrl: result
        })
      });
  }, []);

  const onRemove = useCallback((e) => {
    e.stopPropagation();
    if(props.onChange) props.onChange(null);
    setState({imageUrl: null});
  }, [])

  return <Upload
    ref={ref}
    beforeUpload={() => false}
    accept="image/jpeg,image/png"
    multiple={false}
    onChange={handleChange}
    showUploadList={false}
    listType="picture"
    style={props.style || {}}
  >
    <UploadButtonContainer >
      {
        state.imageUrl
          ? <ImageContainer>
            <img src={state.imageUrl} />
            <DeleteOverlay onClick={onRemove}>
              <DeleteOutlined style={{fontSize: '30px'}} />
            </DeleteOverlay>
          </ImageContainer>
          : <>
            <CloudUploadOutlined />
            <small>Carica il logo</small>
          </>
      }
    </UploadButtonContainer>

  </Upload>
});

export default UploadInput;