import React, { useEffect, useMemo, useState } from 'react';
import { PageHeader, Layout, Menu, Spin, Select } from 'antd';
import { Link, navigate, Redirect, Router } from '@reach/router';
import styled from 'styled-components';
import { Storage } from 'aws-amplify';

import User from '../../../../components/User';
import { UserOutlined, SettingOutlined, CarOutlined, DashboardOutlined, LoadingOutlined } from '@ant-design/icons';

import Impostazioni from './impostazioni';
// import Partecipanti from './partecipanti';
// import Teams from './teams';
// import Home from './home';

import cm from '../../../../controllers';
import Avatar from 'antd/lib/avatar/avatar';
import withS3Src from '../../../../hoc/withS3Src';
import { observer } from 'mobx-react';
import { reaction } from 'mobx';
import Gestione from './gestione';


const {Header, Content} = Layout;


const Title = observer(() => {
  if (!cm.controllers.eventi.current) {
    return <LoadingOutlined />
  } else {
    return cm.controllers.eventi.current.titolo
  }
})

const Subtitle = observer(() => {
  if (!cm.controllers.eventi.current) {
    return ''
  } else {
    return <small>Dal {new Date(cm.controllers.eventi.current.startDate).toLocaleDateString()} al {new Date(cm.controllers.eventi.current.endDate).toLocaleDateString()}</small>
  }
})

const SingoloEvento = (props) => {
  const [avatar, setAvatar] = useState(null);

  
  
  useEffect(() => {
    const load = async () => {
      const event = await cm.controllers.eventi.get(props.id);
      cm.controllers.eventi.current = event;
    }
    load();
  }, [props.id])

  
  reaction(() => cm.controllers.eventi.current, async(current) => {
    const result = await Storage.get(current.logo);
    setAvatar(result)
  });

  useEffect(() => {
    if(
      !props.location.pathname.endsWith('gestione') &&
      !props.location.pathname.endsWith('impostazioni')
    ) {
      navigate(`${props.location.pathname}/gestione`)
    }
  }, [])


  return <Layout style={{height: '100%'}}>
    <PageHeader 
      style={{padding: '16px 2%'}}
      title={<Title />}
      subTitle={<Subtitle />}
      avatar={{
        src: avatar 
      }}
      extra={
        <User />
      }
    >
    </PageHeader>
      <Content style={{height: 'calc(100vh - 73.15px)', minHeight: '1080px'}}>
       <Layout style={{height: '100%'}}>
         <Content style={{paddingTop: '0'}}>
            <Router style={{height: '100%'}}>
              <Impostazioni path="/impostazioni" />
              <Gestione path="/gestione" />
            </Router>
          </Content>
        </Layout>    
     </Content>
    </Layout>
  
}

export default SingoloEvento;