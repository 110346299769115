import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import {Router, Redirect} from '@reach/router';
import { Card, Alert } from 'antd';

import Login from './login';
import Forgot from './forgot';
import Challenge from './challenge/index';
import ResetPassword from './reset-password';

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  background: url('/auth-background.jpg');
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content: center;
  z-index: 1;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    z-index: 2;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(29, 51, 84,.5)
  }
`;

const ContentCard = styled(Card)`
  max-width: 400px;
  width: 100%;
  position:relative;
  z-index: 3;
`;

const LoginPage = () => {
  const [error, setError] = useState(false);
  const showError = useCallback((error) => {setError(error)}, []);
  const clearError = useCallback(() => {setError(false)}, [])
  const bindError = {showError, clearError};
  return (
    <Container>
      <ContentCard>
        <Router>
          <Login path="/login" {...bindError}/>
          <Forgot path="/forgot"  {...bindError}/>
          <ResetPassword path="/reset-password" {...bindError}/>
          <Challenge path="/challenge/*" {...bindError}/>
          <Redirect from="/" to="/auth/login" {...bindError}/>
        </Router>
      </ContentCard>
      {
        error
          ? <Alert style={{position: 'relative', zIndex: 3, width: '100%', maxWidth: '400px'}} type={"error"} message={error} />
          : null
      }
    </Container>
  )
};

export default LoginPage;