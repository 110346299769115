import { LoadingOutlined, MessageOutlined } from '@ant-design/icons';
import { Button, Descriptions, message, Space, Typography } from 'antd';
import {Avatar} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import {useLocation} from '@reach/router'

import cm from '../../controllers';

const ColorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];

const UserDetail = ({id, closeAll}) => {
  const [utente, setUtente] = useState(null);
  const [loadingChat, setLoadingChat] = useState(false);
  const location = useLocation();

  const chat = useCallback(async () => {
    try {
      const channelName = `${utente.nome} ${utente.cognome}`;
      setLoadingChat(true);
      if (cm.controllers.chat.channels.has(channelName)) {
        await cm.controllers.chat.updateAttributes(channelName, {
          visible: true
        }) 
        cm.controllers.chat.currentChannel = channelName;
        closeAll();
        cm.controllers.chat.open = true;
      }
    } catch (e) {
      console.dir(e);
      message.error('Impossibile aprire la chat');
    } finally {
      setLoadingChat(false);
    }
  }, [utente]);

  useEffect(() => {
    const load = async () => {
      try {
        const result = await cm.controllers.cts.get(id)
        setUtente(result);
      } catch(e) {
        message.error('Impossibile ottenere informazaioni sull\'utente');
      } 
    }
    if (id) {
      load();
    }
  }, [id]);

  return !utente
    ?  <LoadingOutlined />
    : <div>
      <header style={{
        display: 'flex',
        justifyContent: 'space-between', 
        alignItems: 'center',
        marginTop: '20px',
        marginBottom: '40px'
      }}>
        <Space>
          <Avatar size={64} style={{
              background: ColorList[(utente.nome.length + utente.cognome.length) % ColorList.length],
              marginRight: '40px'
            }}
          >
            {utente.nome.charAt(0).toUpperCase()}{utente.cognome.charAt(0).toUpperCase()}
          </Avatar>
          <div>
            <Typography.Title level={3} style={{margin: 0}}>{utente.nome}</Typography.Title>
            <Typography.Title level={3} style={{margin: 0}}>{utente.cognome}</Typography.Title>
          </div>
        </Space>
        
          {
            location.pathname.endsWith('gestione')
              ? <Button loading={loadingChat} icon={<MessageOutlined />} onClick={chat}>Scrivi in chat</Button>
              : null
          }
        </header>
      <Descriptions bordered>
        <Descriptions.Item label="Email">
          {utente.email}
        </Descriptions.Item>
        <Descriptions.Item label="Telefono">
          {utente.telefono}
        </Descriptions.Item>

      </Descriptions>
      <footer style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '20px'
      }}>
        <small>Creato il {new Date(utente.createdAt).toLocaleString()}</small>
        <small>Ultimo aggiornamento il {new Date(utente.updatedAt).toLocaleString()}</small>
      </footer>
    </div>
};

export default UserDetail