import { GroupOutlined, SendOutlined, TeamOutlined } from '@ant-design/icons';
import { Input, Tabs, Form, Button, Typography, Space, Badge } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { useCallback } from 'react';
import styled from 'styled-components';

import cm from '../../controllers';
import useUserManagement from './useUserManager';

const Body = styled.div`
  padding: 10px;
  background: ${props => props.me ? '#50AA19' : '#3582DB'};
  color:white;
  border-radius: 5px;
`;

const MessageList = observer(({style}) => {
  const container = useRef();

  useEffect(() => {
    const current = cm.controllers.chat.channels.get(cm.controllers.chat.currentChannel);

    if (current) {
      current.instance.setAllMessagesConsumed()
      current.unread = 0;
    }
  }, [])

  useEffect(() => {
    if (container.current) {
      container.current.scrollTop = container.current.scrollHeight;
    }
  }, [])

  const current = cm.controllers.chat.channels.get(cm.controllers.chat.currentChannel)
  const messages = current ? current.messages.items : [];  

  return <div style={{padding: '15px',...style}} ref={container}>
    {
      messages.map((message, idx) => {
          return <div style={{
            marginBottom: '10px',
            display: 'flex',
            justifyContent: message.author !== cm.controllers.chat.username
              ? 'flex-end'
              : 'flex-start',
          }} key={`${cm.controllers.chat.currentChannel}-message-${idx}`}>
            <Space direction="vertical" size={2} style={{maxWidth: '70%'}}>
                {
                  message.author !== cm.controllers.chat.username 
                    ? <Typography.Title level={5} style={{
                      textAlign: message.author !== cm.controllers.chat.username
                        ? 'right'
                        : 'left'
                    }}>{message.author}</Typography.Title> 
                    : null
                }
                <Body me={message.author === cm.controllers.chat.username}>
                {message.body} 
                </Body>
                <div style={{
                  display: 'flex',
                  justifyContent: message.author !== cm.controllers.chat.username
                    ? 'flex-end'
                    : 'flex-start'
                }}>
                  <small>
                  {
                    idx === 0 || (message.state.timestamp.getTime() - messages[idx - 1].state.timestamp.getTime() > 60000)
                      ? message.state.timestamp.toLocaleString()
                      : null
                    }
                  </small>
                </div>
            </Space>
          </div>
        })
    }
  </div>
});


const ChannelList = observer(() => {

  const [form] = Form.useForm();
  const [UserDrawer, {toggle: toggleUsers, close: closeUsers}] = useUserManagement();

  const setCurrent = useCallback((value) => {
    cm.controllers.chat.currentChannel = value;
  }, []);

  return <Tabs
      activeKey={cm.controllers.chat.currentChannel}
      style={{height: '100%'}} 
      type="editable-card"
      onChange={(change) => {
        closeUsers();
        setCurrent(change);
      }}
      onEdit={async (targetKey, action) => {
        if (action === 'add') {
          const result = prompt('Nome nuovo canale');
          if (result.length === 0) return;
          await cm.controllers.chat.createChannel(result);
          await cm.controllers.chat.joinChannel(result);
          cm.controllers.chat.currentChannel = result;
        } else if (action === 'remove') {
          await cm.controllers.chat.removeChannel(targetKey)
        }
      }}
      tabBarExtraContent={{
        right: <Button
          style={{marginRight: '5px'}}
          icon={<TeamOutlined />}
          onClick={() => toggleUsers(cm.controllers.chat.currentChannel)}
        >
          Gestisci utenti
        </Button>
      }}
    >
      {
        Array
          .from(cm.controllers.chat.channels.keys())
          .sort(ch => ch === 'general' ? -1 : 0)
          .filter(ch => {
            const instance = cm.controllers.chat.channels.get(ch).instance;
            
            return instance.attributes.visible !== false;
          })
          .map(key => {
            return <Tabs.TabPane
              closeIcon={key==='general' ? <></> : undefined}
              style={{display: key === cm.controllers.chat.currentChannel ? 'flex' : 'none', flexDirection:'column', position: 'relative'}}
              key={key}
              tab={<Space>
                <span>{key.charAt(0).toUpperCase()}{key.substr(1)}</span>
                <Badge 
                  style={{pointerEvents: 'none',color: 'white' }} 
                  count={
                    cm.controllers.chat.channels.get(key).unread
                  } 
                />
              </Space>}
            >
              <MessageList channel={key} style={{height: '100%', overflow: 'auto'}} />
              <Form form={form} onFinish={async (values) => {
                await cm.controllers.chat.sendMessage(values.text);
                form.resetFields();
              }} style={{
                width: '100%',
                display: 'flex',
                alignItems: 'stretch'
              }}>
                <Form.Item name="text" style={{margin: 0, width: '100%'}}>
                  <TextArea style={{resize: 'none'}} placeholder="Scrivi il tuo messaggio" />
                </Form.Item>
                <Button style={{height: '100%'}} htmlType="submit" type="primary" icon={<SendOutlined />} >
                  Invia
                </Button>
              </Form>
              <UserDrawer />
            </Tabs.TabPane>
          })
      }
    </Tabs>
});

export default ChannelList;