import React, { useState, useMemo, useEffect } from 'react';
import {Form, Input, Button} from 'antd';
import querystring from 'querystring';

import cm from '../../controllers';
import { useLocation, navigate } from '@reach/router';

const ResetPassword = ({showError, clearError}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      const {email} = querystring.parse(location.search.slice(1));
      if (email) {
        form.setFieldsValue({
          email
        })
      }
    }
  }, [location.search])
  

  return <>
    <h2>Reimposta la tua password</h2>
    <p>Inserisci la nuova password e il codice ricevuto tramite <strong>Email</strong></p>
    <Form
      form={form}
      onFinish={async (values) => {
        try {
          clearError()
          setLoading(true);
          await cm.controllers.auth.resetPassword(values.email, values.password, values.codice)
          navigate('/auth/login')
        } catch (e) {
          console.dir(e);
          switch(e.code) {
            case "CodeMismatchException":
              showError('Il codice fornito non è valido')
              break;
            default:
              showError('Si è verificato un erorre sconosciuto');
              break;
          }
        } finally {
          setLoading(false);
        }
      }}
    >
       <Form.Item
        labelCol={{lg: 24}}
        labelAlign="left"
        wrapperCol={{lg: 24}}
        name="email"
        hasFeedback
        label="Email"
        rules={[
          {required: true, message: 'Campo necessario'},
        ]}
      > 
        <Input type="email" />
      </Form.Item>
      <Form.Item
        labelCol={{lg: 24}}
        labelAlign="left"
        wrapperCol={{lg: 24}}
        name="password"
        hasFeedback
        label="Nuova password"
        rules={[
          {required: true, message: 'Campo necessario'},
          () => ({
            validator (rule, value) {
              if (/^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9]).{8,})\S$/.test(value)) {
                return Promise.resolve()
              }         
              return Promise.reject('La password non soddisfa i requisiti');
            }
          })
        ]}
      > 
        <Input.Password />
      </Form.Item>
      <Form.Item
        labelCol={{lg: 24}}
        labelAlign="left"
        wrapperCol={{lg: 24}}
        name="confirm"
        label="Conferma la password"
        hasFeedback
        dependencies={['password']}
        rules={[
          {required: true, message: 'Campo necessario'},
          ({getFieldValue}) => ({
            validator (rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject('Le due password non coincidono');
            }
          })
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        rules={[{required: true, message: 'Inserisci il codice di conferma'}]}
        name="codice"
        hasFeedback
        label="Codice di conferma"
      > 
        <Input />
      </Form.Item>
      <Form.Item wrapperCol={{lg: 24}} style={{textAlign: 'right'}}>
        <Button loading={loading} type="primary" htmlType="submit">Invia</Button>
      </Form.Item>
    </Form>
  </>
};

export default ResetPassword;