import { Button, Result } from 'antd';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  .ant-result-title {
    color: white !important;
  }
  .ant-result-subtitle {
    color: white !important;
  }
`



class ErrorBoundry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error) {
    return {hasError:true};
  }

  componentDidCatch(error, errorInfo) {
    
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container>
          <Result
            status="500"
            title="Errore"
            subTitle="Si è verificato un errore imprevisto"
            extra={<Button onClick={() => window.location='/'} type="primary">Ricarica applicazione</Button>}
          />
        </Container>
      )
    }
    return this.props.children;
  }
}

export default ErrorBoundry;
