import React, { useMemo, useState } from 'react';
import { Space, Button, Popconfirm, Table, Card, Descriptions, Tooltip } from 'antd';
import { DeleteOutlined, EditTwoTone, LoadingOutlined } from '@ant-design/icons';
import {observer} from 'mobx-react'

import useTableSearch from '../../hooks/useTableSearch';
import cm from '../../controllers';
import useModalForm from '../../hooks/useModalForm';
import CreateUserForm from '../Forms/CreateUser';

const CTSTable = ({loading}) => {
  const [internalLoading, setInternalLoading] = useState(false);

  const [{show}, Modal] = useModalForm(CreateUserForm);
  
  const nomeSearch = useTableSearch('nome', {
    searchFunction: (value, record) => {
      return record.nome.toLowerCase()
        .indexOf(value.toLowerCase()) !== -1;
    }
  });
  const cognomeSearch = useTableSearch('cognome', {
    searchFunction: (value, record) => {
      return record.cognome.toLowerCase()
        .indexOf(value.toLowerCase()) !== -1;
    }
  });
  
  const columns = useMemo(() => [
    {
      title: "Nome",
      dataIndex: "nome",
      key: "nome",
      sorter: {
        compare: (a, b) => a.nome > b.nome ? -1 : 1,
        multiple: 1
      },
      ...nomeSearch
    },
    {
      title: "Cognome",
      dataIndex: "cognome",
      key: "cognome",
      sorter: {
        compare: (a, b) => a.nome > b.nome ? -1 : 1,
        multiple: 1
      },
      ...cognomeSearch
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email"
    }, {
      title: "Telefono",
      dataIndex: "telefono",
      key: "telefono"
    },
    {
      key: "actions",
      render: (item) => {
        return <div style={{textAlign: 'right'}} >
          <Space>
            <Button
              icon={<EditTwoTone />}
              onClick={() => {
                show({...item}, `Modifica utente: ${item.nome} ${item.cognome}`)
              }}
            >Modifica</Button>
            {
              !item.teams || item.teams.length === 0
                ? <Popconfirm
                  placement="topRight"
                  title={'Sei sicuro? Questa operazione non è reversibile.'}
                  onConfirm={async () => {
                    setInternalLoading(true);
                    await cm.controllers.cts.delete(item.cognitoRef);
                    setInternalLoading(false);
                  }}
                  okText="Sicuro"
                  cancelText="No"
                  okType="danger"
                ><Button danger  icon={
                  <DeleteOutlined />
                }>Elimina</Button>
                </Popconfirm>
                : <Tooltip placement="right" title="Non puoi eliminare un membro che fa parte di un team">
                  <Button disabled danger icon={
                  <DeleteOutlined />
                }>Elimina</Button>
                </Tooltip>
            }
           
          </Space>
        </div>
      }
    }
  ], []);

  return  <><Table
      pagination={{
        hideOnSinglePage: false,
        showSizeChanger: true,
        showTotal: (total, range) => (
          <span style={{
            display: 'block',
            paddingRight: '10px',
            position: 'relative',
            top: '2px'
          }}>
            Elementi da {range[0]} a {range[1]} di <strong>{total}</strong>
          </span>
        )
      }}
      dataSource={cm.controllers.cts.users}
      loading={loading || internalLoading}                
      columns={columns}
      rowKey={(item) => item.cognitoRef}
      expandable={{
        rowExpandable: () => true,
        expandedRowRender: (record) => {
          return <Card>
            <Descriptions bordered>
              <Descriptions.Item label="Indirizzo">
                {record.indirizzo ? record.indirizzo : 'Non pervenuto'}
              </Descriptions.Item>
              
              <Descriptions.Item label="Codice fiscale">
                {record.CF ? record.CF.toUpperCase() : 'Non pervenuto'}
              </Descriptions.Item>
              <Descriptions.Item label="Data di nascita">
                {new Date(record.dataNascita).toLocaleDateString()}
              </Descriptions.Item>
              <Descriptions.Item label="Luogo di nascita">
                {record.luogoNascita ? record.luogoNascita : 'Non pervenuto'}
              </Descriptions.Item>  
              <Descriptions.Item label="Ultimo aggiornamento">
                {new Date(record.updatedAt).toLocaleString()}
              </Descriptions.Item>
              <Descriptions.Item label="cognitoRef">
                {record.cognitoRef}
              </Descriptions.Item>
            </Descriptions>
        </Card>
      }
    }}
  />{Modal}</>
};

export default observer(CTSTable);