import { Button, Card, Table } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { CloudUploadOutlined, PlusCircleOutlined } from '@ant-design/icons';

import cm from '../../controllers'
import { reaction } from 'mobx';

const GestionePartecipanti = () => {
  const [dataSource, setDatasource] = useState(null);

  useEffect(() => {
    let r;
    const load = async () => {
      const result = await cm.controllers.api.get(`/eventi/${cm.controllers.eventi.current.id}/partecipanti`);
      setDatasource(result.Items)
    }
    reaction(
      () => cm.controllers.eventi.current,
      async (c, reac) => {
        r = reac;
        if (cm.controllers.eventi.current) {
          await load();
        }
      },
      {fireImmediately: true}
    )
    return () => {
      if (r && r.dispose) {
        r.dispose();
      }
    }
  }, []);

  const columns = useMemo(() => [{
    title: 'Numero di gara',
    key: 'numDiGara',
    dataIndex: 'numeroDiGara',
    sorter: {
      compare: (a, b) => parseInt(a.numeroDiGara) > parseInt(b.numeroDiGara) ? 1 : -1,
    } 
  },{
    title: 'Pilota',
    sorter: {
      compare: (a, b) => `${a.pilota.nome} ${a.pilota.cognome}` > `${b.pilota.nome} ${b.pilota.cognome}` ? -1 : 1,
    } ,
    children: [{
      title: 'Nome',
      key: 'pilotaNome',
      dataIndex: 'pilota',
      render: (pilota) => pilota.nome
    }, {
      title: 'Nazione',
      key: 'pilotaNome',
      dataIndex: 'pilota',
      render: (pilota) => pilota.nazione
    }] 
  }, {
    title: 'Copilota',
    sorter: {
      compare: (a, b) => `${a.copilota.nome} ${a.copilota.cognome}` > `${b.copilota.nome} ${b.copilota.cognome}` ? -1 : 1,
    },
    children: [{
      title: 'Nome',
      key: 'copilotaNome',
      dataIndex: 'copilota',
      render: (copilota) => copilota.nome
    }, {
      title: 'Nazione',
      key: 'pilotaNome',
      dataIndex: 'copilota',
      render: (pilota) => pilota.nazione
    }]
  }, {
    title: 'Vettura',
    children: [{
      title: 'Marca',
      key: 'marca',
      dataIndex: 'auto',
      render: (vettura) => vettura.marca
    }, {
      title: 'Modello',
      key: 'modello',
      dataIndex: 'auto',
      render: (vettura) => vettura.modello
    }, {
      title: 'Anno',
      key: 'anno',
      dataIndex: 'auto',
      render: (vettura) => vettura.anno
    }]
  }, {
    title: 'Coeff',
    key: 'coeff',
    dataIndex: 'coeff'
  }, {
    title: 'Note',
    key: 'note',
    dataIndex: 'note'
  }, {
    title: 'Sponsor',
    key: 'sponsor',
    dataIndex:'sponsor'
  }], [])
  return <Card title="Gestione partecipanti" id="partecipanti" extra={
    <Button disabled type="primary" icon={<CloudUploadOutlined />} >
      Importa csv
    </Button>}>
    <Table 
      loading={!dataSource}
      dataSource={dataSource}
      columns={columns}
      rowKey={item => item.id}
    />
    </Card>
    
};

export default GestionePartecipanti;