const scrollToAnimation = (scroller, target, options = {}) => {
  const opts = Object.assign({
    duration: 600
  }, options)
  const element = document.getElementById(target.substr(1));
  const elementOffset = element.offsetTop;
  const delta = elementOffset - scroller.scrollTop;
  const frames = opts.duration/1000 * 60;

  const ppf = (delta/frames);

  let prev = 0;
  const animation = () => {
    prev = scroller.scrollTop;
    scroller.scrollTop += ppf;
    if (delta > 0) {
      if (
        scroller.scrollTop < element.offsetTop && 
        prev !== scroller.scrollTop
      ) {
        window.requestAnimationFrame(animation);
      }
    } else {
      if (
        scroller.scrollTop > element.offsetTop &&
        prev !== scroller.scrollTop
      ) {
        window.requestAnimationFrame(animation)
      }
    }
  };
  
  window.requestAnimationFrame(animation);
};

export default scrollToAnimation;