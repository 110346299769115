import React, { useMemo } from 'react';
import { List, Space, Button, Avatar, Tag, Empty } from 'antd';
import { observer } from 'mobx-react'
import styled from 'styled-components'
import {navigate} from '@reach/router';

import cm from '../../controllers';
import withS3Src from '../../hoc/withS3Src';
import { DashboardOutlined, SettingOutlined } from '@ant-design/icons';
import moment from 'moment';

const Container = styled.div`
  display: flex;
  width: 100%;
  position:relative;
  padding: 60px 40px;
  z-index: 3;
  align-items: center;
  color: #fff;
  justify-content: space-between;
  overflow:hidden;
  border-radius: 5px;
  &:before {
    content:  '';
    background: url('/photo${props => props.length % 2}.jpg');
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    filter: grayscale(100%) blur(5px);
    z-index: -2;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.3);
    z-index: -1;
  }
  h2 {
    font-size: 2.5em;
    margin: 0; 
    color: #fff;
  }
  h4 {
    color: #fff;
  }
`;

const S3Avatar = withS3Src(Avatar);

export const SingleEvento = ({item, inCorso}) => {
  return <Container length={new Date(item.startDate).getTime()}>
    <Space size={40}>
      <S3Avatar src={item.logo} size={80}/>
      <div>
        <h2>{item.titolo}</h2>
        <h4>Dal {new Date(item.startDate).toLocaleDateString()} al {new Date(item.endDate).toLocaleDateString()}</h4>
        {
          item.enabled 
            ? <Tag style={{
              background: '#62B519',
              color: '#fff'
            }} >Attivo</Tag>
            : <Tag style={{
              background: '#DB2E30',
              color: '#fff'
            }}>Non attivo</Tag>
        }
      </div>
    </Space>
    <Space>
      {
        inCorso
          ? <Button type="primary" icon={<DashboardOutlined />} onClick={() => {
            navigate(`/dashboard/eventi/${item.id}/gestione`)
          }}>
            Dashboard
          </Button>
          : null
      }
      <Button type="primary" icon={<SettingOutlined />} onClick={() => {
        navigate(`/dashboard/eventi/${item.id}/impostazioni`)
      }} >
        Configura
      </Button>
    </Space>
  </Container>
}

const EventiList = observer((props) => {

  const eventi = props.inCorso === false
    ? cm.controllers.eventi.list
        .filter(evento => {
          return !moment().isBetween(moment(evento.startDate).subtract(1, 'day'), moment(evento.endDate).add(1, 'days'));
        })
    : props.inCorso === true 
        ? cm.controllers.eventi.list
          .filter(evento => {
            return moment().isBetween(moment(evento.startDate).subtract(1, 'day'), moment(evento.endDate).add(1, 'days'));
          })
        : cm.controllers.eventi.list;

  return !eventi || eventi.length === 0
    ? <Empty
        description="Nessun evento"
        style={{
          color: '#fff'
        }}
      />
    : <List
      style={{color: '#fff'}} 
      loading={props.loading}
      dataSource={eventi}
      renderItem={(item) => <List.Item >
        <SingleEvento item={item} inCorso={props.inCorso} />
      </List.Item>}
    />
});

export default EventiList;


