import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import { navigate } from '@reach/router';
import { Button, message, Space, Modal } from 'antd';
import { observer } from 'mobx-react';
import React, { useCallback, useState } from 'react';

import cm from '../../controllers';


const EliminazioneEvento = observer((props) => {
  const [deleting, setDeleting] = useState(false);

  const onClick = useCallback(async () => {
    Modal.confirm({
      icon: <WarningOutlined />,
      cancelText: 'Annulla',
      title: 'Sei sicuro?',
      okText: "Sicuro",
      content: <p>Stai per cancellare un'evento, questa operazione è <strong>irreversibile</strong>. Tutte le configurazioni verranno cancellate.</p>,
      okType: 'danger',
      onCancel: () => {
        setDeleting(false);
      },
      onOk: async () => {
        try { 
          setDeleting(true);
          await cm.controllers.eventi.delete(cm.controllers.eventi.current.id);
          await navigate('/dashboard/eventi')
        } catch (e) {
          message.error('Errore durante l\'eliminazione')
          setDeleting(false);
        }
      },
      okButtonProps: {
        loading: deleting
      }
    }) 
  }, [])

  return <Space>
    <Button disabled={
      !cm.controllers.eventi.current || 
      cm.controllers.eventi.current.enabled
    } type="primary" loading={deleting} onClick={onClick} danger icon={<DeleteOutlined />}>
      Elimina Evento
    </Button>
    {
      !cm.controllers.eventi.current || cm.controllers.eventi.current.enabled
        ? <small>Impossibile eliminare un evento attivo</small>
        : null
    }
  </Space>
});

export default EliminazioneEvento;