import React from 'react';
import {Input, DatePicker, Form, message} from 'antd';
import styled from 'styled-components';
import {Storage} from 'aws-amplify';

import UploadInput from '../UploadInput';

import cm from '../../controllers';

const CustomForm = styled(Form)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;



const CreateEvento = (props) => {
  return <CustomForm
    form={props.form}
    labelCol={{lg: 24}}
    wrapperCol={{lg: 24}}
    labelAlign="left"
    colon={false}
    onFinish={ async (values) => {
      try {
        props.setLoading(true);
        let fileKey = null;
        if (values.logo) {
          message.loading({
            content: 'Caricamento del logo in corso',
            key: 'logo'
          });
          const {logo} = values;
          const extension = values.logo.name.split('.').slice(-1);
  
          const res = await Storage.put(`${logo.uid}.${extension}`, logo)
  
          fileKey = res.key;
          message.success({
            content: 'Logo caricato',
            key: 'logo'
          })
        }
        
        await cm.controllers.eventi.create({
          titolo: values.titolo,
          startDate: values.date[0].toISOString(),
          endDate: values.date[1].toISOString(),
          logo: fileKey
        })
        if (props.onComplete) props.onComplete();
      } catch (e) {
        message.error('Errore durante la creazione dell\'evento')
      } finally {
        props.setLoading(false);
      }
    }}
  >
    <Form.Item name="logo">
      <UploadInput />
    </Form.Item>
    <div style={{marginLeft: '20px'}}>  
      <Form.Item name="titolo" label="Titolo" rules={[
        {required: true, message: 'Questo campo è obbligatorio'}
      ]}>
        <Input />
      </Form.Item>
      <Form.Item name="date" label="Date" rules={[
        {required: true, message: 'Questo campo è obbligatorio'},
        () => ({
          validator(schema, value) {
            if (value._d < new Date()) {
              return Promise.reject('La data non può essere precedente a quella odierna')
            } else {
              return Promise.resolve()
            }
          }
        })
      ]}>
        <DatePicker.RangePicker style={{width: '100%'}}/>
      </Form.Item>
    </div>
  </CustomForm>
}

export default CreateEvento;