import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import {Button, DatePicker, Form, Input, message} from 'antd';
import moment from 'moment';
import _ from 'lodash';

import UploadInput from '../UploadInput';
import cm from '../../controllers';
import { LoadingOutlined } from '@ant-design/icons';
import { Storage } from 'aws-amplify';
import { reaction } from 'mobx';

const UpdateEventForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState({})
  // const initialValues = cm.controllers.eventi.current ? {
  //   logo: cm.controllers.eventi.current.logo,
  //   titolo: cm.controllers.eventi.current.titolo,
  //   date: [moment(cm.controllers.eventi.current.startDate),moment(cm.controllers.eventi.current.endDate)]
  // } : {};

  useEffect(() => {
    let r;
    reaction(
      () => cm.controllers.eventi.current,
      (c, reaction) => {
        r = reaction;
        const current = cm.controllers.eventi.current;
        
        const val = current ? {
          logo: current.logo,
          titolo: current.titolo,
          date: [moment(current.startDate), moment(current.endDate)]
        } : {};

        form.setFieldsValue(val);
        setInitialValues(val);
      }, {fireImmediately: true}
    );
    return () => r.dispose()
  }, [])

  return !cm.controllers.eventi.current ? <LoadingOutlined /> : <Form style={{
      display: 'flex',
      padding: '40px',
    }}
    form={form}
    onFieldsChange={() => setIsTouched(!_.isEqual(initialValues, form.getFieldsValue()))}
    initialValues={initialValues}
    onFinish={async (values) => {
      if (!form.isFieldsTouched()) return;
      try {
        setLoading(true);
        let file = values.logo; 
        if (values.logo !== cm.controllers.eventi.current.logo) {
          message.loading({
            content: 'Caricamento del logo in corso',
            key: 'logo'
          });
          const extension = values.logo.name.split('.').slice(-1);
  
          const res = await Storage.put(`${values.logo.uid}.${extension}`, values.logo);
          file = res.key;
          message.success({
            content: 'Logo caricato',
            key: 'logo'
          })
        }

        await cm.controllers.eventi.update(cm.controllers.eventi.current.id,{
          titolo: values.titolo,
          startDate: values.date[0].toISOString(),
          endDate: values.date[1].toISOString(),
          logo: file
        })
      } catch (e) {
        message.error('Errore durante l\'aggiornamento')
      } finally {
        setLoading(false);
      }
    }}
  >
    <div style={{marginRight: '50px', display: 'inline-flex', alignItems: 'center'}}>
      <Form.Item name="logo">
        <UploadInput />
      </Form.Item>
    </div>
    <div style={{width: '100%'}}>
      <Form.Item name="titolo" label="Titolo" rules={[{required: true, message: 'Campo richiesto'}]}>
        <Input />
      </Form.Item>
      <Form.Item name="date" label="Date della gara" rules={[{required: true, message: 'Campo richiesto'}]}>
        <DatePicker.RangePicker style={{width: '100%'}} />
      </Form.Item>
      <div style={{
        display: 'flex',
        justifyContent: 'flex-end'
      }}>

      <Button 
        type="primary" 
        htmlType="submit"
        loading={loading}
        disabled={!isTouched}
      >Aggiorna</Button>
      </div>
    </div>
  </Form>
};


export default observer(UpdateEventForm);