import React, { useState, useEffect } from 'react';
import {Router, Redirect} from '@reach/router';

import auth from './utils/guard';
import Dashboard from './pages/dashboard';
import withGuard from './hoc/withGuard';
import Auth from './pages/auth'
import LoadingIcon from './components/loading-icon';

import cm from './controllers';
import ErrorBoundary from './components/error-boundry';
import Unauthorized from './pages/unauthorized';

const ProtectedDashboard = withGuard(Dashboard)


const App = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const startControllers = async () => {
      await cm.start();
      setLoading(false);
    }
    startControllers();
  }, []);

  return <ErrorBoundary>
    {
      loading
        ? <LoadingIcon />
        : <Router style={{height: '100%'}}>
          <Unauthorized path="/unauthorized"/>
          <Auth path="/auth/*" />
          <ProtectedDashboard path='/dashboard/*' guard={auth} />
          <Redirect from="/" to="/dashboard/eventi" />
        </Router>
    }
  </ErrorBoundary>
}

export default App;