import React from 'react';
import { Form, Input, DatePicker, Row, Col, message } from 'antd';

import cm from '../../controllers';

const CreateUserForm = (props) => {

  return <Form
    form={props.form}
    labelAlign="left"
    labelCol={{lg: 24}}
    wrapperCol={{lg: 24}}
    onFinish={async (values) => {
      try {
        props.setLoading(true);
        if (values.cognitoRef && values.cognitoRef.length > 0) {
          const id = values.cognitoRef;
          await cm.controllers.cts.update(id, values)
        } else {
          await cm.controllers.cts.create(values);
        }
        if (props.onComplete) {
          props.onComplete();
        }
      } catch (e) {
        if (values.cognitoRef && values.cognitoRef.length > 0) {
          message.error('Errore durante la modifica')
        } else { 
          console.dir(e);
          message.error('Errore durante la creazione')
        }
      } finally {
        props.setLoading(false)
      }
  }}
  >
   <Form.Item
      name="cognitoRef"
      hidden
      style={{height: '0px'}}
    >
      <Input type="hidden" />          
    </Form.Item>
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          name="nome"
          label="Nome"
          hasFeedback
          rules={[
            {required: true, message: 'Campo necessario'}
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="cognome" 
          label="Cognome" 
          hasFeedback
          rules={[
            {required: true, message: 'Campo necessario'}
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="dataNascita" 
          label="Data di nascita" 
          hasFeedback
          rules={[
            {required: true, message: 'Campo necessario'},
            () => ({
              validator (schema, value) {
                if (value._d < new Date()) {
                  return Promise.resolve();
                } else {
                  return Promise.reject('La data deve essere precedente alla data odierna')
                }
              }
            })
          ]}
        >
          <DatePicker style={{width: '100%'}} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item 
          name="luogoNascita" 
          label="Luogo di nascita" 
          hasFeedback
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
    <Form.Item 
      name="indirizzo" 
      label="Indirizzo" 
      hasFeedback
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="CF" 
      label="CF" 
      hasFeedback
      rules={[
        () => ({
          validator(schema, value) {
            if (!value || value.length === 0) return Promise.resolve();
            if (/[a-z]{6}[0-9]{2}[a-z][0-9]{2}[a-z][0-9]{3}[a-z]$/i.test(value)) {
              return Promise.resolve();
            } 
            return Promise.reject('Codice fiscale non valido')
          }
        })
      ]}  
    >
      <Input />
    </Form.Item>
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          name="email"
          label="Email"
          hasFeedback
          rules={[
            {required: true, message: 'Campo necessario'},
            {type: 'email', message: 'Email non valida'}
          ]}
        >
          <Input />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item 
          name="telefono"
          label="Telefono"
          hasFeedback
          rules={[{required: true, message: 'Campo necessario'}]}  
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
    
  </Form>;
}

export default CreateUserForm;