import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { Space, Button, Popconfirm, Layout, PageHeader, Card, Table, Descriptions, Result } from 'antd';
import { EditTwoTone, DeleteOutlined, PlusCircleOutlined, ReloadOutlined } from '@ant-design/icons';

import useModalForm from '../../hooks/useModalForm';
import useTableSearch from '../../hooks/useTableSearch';
import CreateUserForm from '../../components/Forms/CreateUser';
import User from '../../components/User';
import Container from '../../components/Container';

import cm from '../../controllers'
import CTSTable from '../../components/cts/table';

const {Content} = Layout;

const CTS = () => {
  const [loading, setLoading] = useState(false);

  const [{show}, Modal] = useModalForm(CreateUserForm);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      await cm.controllers.cts.fetch();
      setLoading(false);
    }
    if (cm.controllers.cts.fetched === false) {
      fetchUsers();
    }
  }, [])

  return <Layout
    style={{ width: '100%' }}
  >
    <PageHeader 
      title="CTS" 
      subTitle="Gestione e amministrazione utenti"
      extra={
        <User />
      }
    />
    <Content style={{paddingTop: '40px'}}>
      <Container>
        <Card title="Lista utenti" extra={
          <Button
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={() => show(null, 'Crea utente')}
          >Aggiungi utente</Button>
        }>
          
          <CTSTable loading={loading}/>
          
        </Card>
      </Container>
    </Content>
    {Modal}
  </Layout>
};

export default CTS;
