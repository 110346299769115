import React, { useState, useCallback } from 'react';
import { Modal, Form } from 'antd';
import moment from 'moment';
import { set } from 'lodash';

const useModalForm = (CustomForm) => {
  const [form] = Form.useForm();
  const [titolo, setTitolo] = useState('Modifica');
  const [props, setProps] = useState({});
  const [options, setOptions] = useState({minWidth: '500px'})

  
  const [visible, setVisible] = useState(false);
  const [loading, setLoading]  = useState(false);

  const show = useCallback((initialData, title, props, minWidth) => {
    if (title) setTitolo(title);
    if (props) setProps(props);
    if (minWidth) setOptions({minWidth})
    if (initialData) {
      const temp = {
        ...initialData
      };
      delete temp['updatedAt'];
      delete temp['createdAt'];
      temp['dataNascita'] = moment(temp['dataNascita'])
      form.setFieldsValue(temp);
    }
    setVisible(true);
  }, [])

  const close = useCallback(() => {
    const temp = Object.keys(form.getFieldsValue())
      .reduce((arr, key) => {
        arr.push({
          value: undefined,
          name: key,
          validating: false,
          errors: [],
          touched: false
        });
        return arr;
      } , [])
    form.setFields(temp);
    setLoading(false);
    setVisible(false);
  }, []);

  const Component = <Modal
    visible={visible}
    closable
    onCancel={close}
    title={titolo} 
    onOk={() => form.submit()}
    okText="Invia"
    cancelText="Cancella"
    confirmLoading={loading}
    style={{
      minWidth: options.minWidth
    }}
  >
    <CustomForm form={form} setLoading={setLoading}  onComplete={close} {...props}/>
  </Modal>;

  return [{show, close}, Component];
}

export default useModalForm;