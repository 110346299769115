import { AimOutlined, CheckCircleFilled, CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined, LoadingOutlined, MessageOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Descriptions, message, Space, Tag, List, Image, Button, Table, Form, Empty, Typography, Divider } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Maps from 'google-map-react'

import cm from '../../../controllers';
import { SEGNALAZIONI_COLORS } from '../../../controllers/Segnalazioni';
import withS3Src from '../../../hoc/withS3Src';
import MarkerSegnalazioni from '../../marker-segnalazioni';
import useDrawer from '../../../hooks/useDrawer';
import { TEAM_COLORS, TEAM_TYPE, TEAM_TYPE_ARRAY } from '../../../controllers/Teams';
import TeamDetail from '../../teams/detail';
import { observer } from 'mobx-react';
import _, { set } from 'lodash';
import useModalForm from '../../../hooks/useModalForm';
import useTableSearch from '../../../hooks/useTableSearch';
import { reaction } from 'mobx';
import UserDetail from '../../cts/detail';
import TextArea from 'antd/lib/input/TextArea';

const S3Image = withS3Src(Image); 

const distanza_punti = (pt1, pt2) => {
  return (Math.sqrt(
    Math.pow(pt1.lat - pt2.lat, 2) +
    Math.pow(pt1.lng - pt2.lng, 2)
  )*100).toFixed(2)
}


const assignTeam = observer((props) => {
  const [teams, setTeams] = useState(null);
  const [selected, setSelected] = useState([]);

  const codiceSearch = useTableSearch('codice', {
    hasRender: true,
    searchFunction: (value, record) => {
      return record.codice.toLowerCase().indexOf(value.toLowerCase()) !== -1
    }
  });
  const equipaggioSearch = useTableSearch('equipaggio', {
    hasRender: true,
    searchFunction: (value, record) => {
      return record.equipaggio
        .map(persona => `${persona.nome} ${persona.cognome}`.toLowerCase().indexOf(value.toLowerCase()))
        .reduce((pre, curr) => pre || curr !== -1, false);
    }
  });

  
  useEffect(() => {
    let r;
    reaction(
      () => cm.controllers.teams.list,
      async (teams, reaction) => {
        r = reaction;
        const t = _.cloneDeep(cm.controllers.teams.list);
        for (let i = 0 ; i < t.length; i++) {
          for (let j = 0; j < t[i].equipaggio.length; j++) {
            if (typeof t[i].equipaggio[j] === 'string') {
              const user = await cm.controllers.cts.get(t[i].equipaggio[j]);
              t[i].equipaggio[j] = user;
            }
          }
        }
        setTeams(t);
      },
      {
        fireImmediately: true
      }
    );
    return () => r.dispose();
  }, [])

  useEffect(() => {
    const load = async () => {
      try {
        await cm.controllers.teams.fetch();
      } catch (e) {
        message.error('Impossibile ottenere la lista dei team');
      }
    };
    if (!cm.controllers.teams.fetched) {
      load()
    }
  }, [])

  const columns = useMemo(() => [
    {
      title: 'Codice equipaggio',
      key: 'codice',
      dataIndex: 'codice',
      sorter: {
        compare: (a, b) => a.codice > b.codice ? -1 : 1,
        multiple: 1
      },
      render: (item) => item,
      ...codiceSearch
   }, {
      title: 'Tipologia',
      key: 'tipo',
      dataIndex: 'tipo',
      render: (item) => {
        return <Tag style={{...TEAM_COLORS[TEAM_TYPE_ARRAY[item]]}}>{TEAM_TYPE_ARRAY[item]}</Tag>;
      },
      sorter: {
        compare: (a, b) => a.tipo > b.tipo ? -1 : 1,
        multiple: 1
      },
      filters: _.keys(TEAM_TYPE).map(key => ({
        text: key,
        value: TEAM_TYPE[key]
      })),
      onFilter: (value, record) => _.castArray(value).indexOf(record.tipo) !== -1
  }, {
    title: 'Equipaggio',
      key: 'equipaggio',
      dataIndex: 'equipaggio',
      render:  (team) => team.map(utente => <Tag 
        onClick={() => props.openDrawer('Dettagli utente', UserDetail, {
          id: utente.cognitoRef
        })} 
        key={utente.cognitoRef}
        style={{cursor: 'pointer'}}
      >
        {utente.nome} {utente.cognome}
      </Tag>),
      ...equipaggioSearch
  }, {
    title: 'Distanza',
    key: 'ultimaPosizione',
    dataIndex: 'ultimaPosizione',
    sorter: {
      compare: (a, b) =>  {
        if (!a.ultimaPosizione && !b.ultimaPosizione) return 0;
        if (!a.ultimaPosizione) return -1;
        if (!b.ultimaPosizione) return -1;
        return parseFloat(distanza_punti(props.coords, {lat: a.ultimaPosizione.latitude, lng: a.ultimaPosizione.longitude})) <
        parseFloat(distanza_punti(props.coords, {lat: b.ultimaPosizione.latitude, lng: b.ultimaPosizione.longitude})) ? -1 : 1
      },
      multiple: 1,
      sortOrder: true
    },
    render: (item) => item
    ? `${distanza_punti(
      props.coords, {lat: item.latitude, lng: item.longitude}
      )} km`
      : 'Posizione del team sconosciuta'
    }], []);
    
    return <Form form={props.form} onFinish={async () => {
      if (selected.length === 0) {
        message.error('Nessun team selezionato')
      } else {
        try {
          props.setLoading(true)
          for(let i = 0; i < selected.length; i++) {
            await cm.controllers.teams.assign(
              selected[i],
              props.segnalazione
              )
            }
          } catch (e) {
            message.error('Errore nell\'assegnazione')
          } finally {
            props.setLoading(false)
            if (props.onComplete) {
              props.onComplete()
            }
          }
        }
      }}>
    <Table 
    loading={!teams}
    dataSource={teams ? teams.filter(i => i.tipo !== TEAM_TYPE.Staff).sort((a, b) => {
      if (!a.ultimaPosizione && !b.ultimaPosizione) return 0;
      if (!a.ultimaPosizione) return -1;
      if (!b.ultimaPosizione) return -1;
      return parseFloat(distanza_punti(props.coords, {lat: a.ultimaPosizione.latitude, lng: a.ultimaPosizione.longitude})) <
      parseFloat(distanza_punti(props.coords, {lat: b.ultimaPosizione.latitude, lng: b.ultimaPosizione.longitude})) ? -1 : 1
    }) : []}

    columns={columns}
    rowKey={item => item.id}
    rowSelection={{
      type: 'checkbox',
      onChange: (selectedRowKeys) => {
        setSelected(selectedRowKeys);
      },
      getCheckboxProps: (record) => ({
        disabled: cm.controllers.teams.free.filter(team => team.id === record.id).length === 0
      })
    }}
  />
  </Form>
});

const SingleTeam = ({item, openDrawer}) => {
  const [loading, setLoading] = useState(false);


  const onRemove = useCallback(async (id) => {
    try {
      setLoading(true)
      await cm.controllers.teams.release(id);
    } catch (e) {
      message.error('Impossibile liberare il team')
    } finally {
      setLoading(false)
    }
  }, [])

  return <div style={{
    display: 'flex',
    aligItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  }}>
    <Space>
      <strong>{item.codice}</strong>
      <Tag style={{
        background: TEAM_COLORS[TEAM_TYPE_ARRAY[item.tipo]]
      }}>
        {TEAM_TYPE_ARRAY[item.tipo]}
      </Tag>
    </Space>
    <Space>
      <Button onClick={() => {
        openDrawer(
          `Informazioni team ${item.codice}`,
          TeamDetail,
          {id: item.id}
        )
      }} icon={<InfoCircleOutlined />}></Button>
      <Button 
        onClick={() => onRemove(item.id)}
      danger icon={<MinusCircleOutlined />} loading={loading}></Button>
    </Space>
  </div>
}

const SingleUser = ({id, openDrawer, closeAll}) => {
  const [loadingChat, setLoadingChat] = useState(false);
  const [user, setUser] = useState(null);

  const chat = useCallback(async () => {
    try {
      const channelName = `${user.nome} ${user.cognome}`;
      setLoadingChat(true);
      if (cm.controllers.chat.channels.has(channelName)) {
        await cm.controllers.chat.updateAttributes(channelName, {
          visible: true
        }) 
        cm.controllers.chat.currentChannel = channelName;
        closeAll();
        cm.controllers.chat.open = true;
      }
    } catch (e) {
      console.dir(e);
      message.error('Impossibile aprire la chat');
    } finally {
      setLoadingChat(false);
    }
  }, [user]);

  useEffect(() => {
    const load = async () => {
      const result = await cm.controllers.cts.get(id);
      setUser(result);
    };
    if (id) {
      load();
    }
  }, [id])


  return <div>
    {
      !user
        ? <LoadingOutlined />
        : <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <Space style={{alignItems: 'center'}}>
            <Typography.Title level={5} style={{marginBottom: 0}}>
              {user.nome} {user.cognome}
            </Typography.Title>
            <span>{user.email}</span>
            <span>{user.telefono}</span>
          </Space>
        <Button loading={loadingChat} icon={<MessageOutlined />} onClick={chat}>Scrivi in chat</Button>

        </div>
    }
  </div>
}

const TeamDetails = ({id, closeAll}) => {
  const [team, setTeam] = useState(null);
  const [Drawer, open] = useDrawer();

  useEffect(() => {
    const load = async () => {
      try{
        const result = await cm.controllers.teams.get(id);
        setTeam(result);
      } catch (e){
        console.error(e)
      }
    };
    if (id) {
      load();
    }
  }, [id])


  return <div>
    {
      !team
        ? 'Team non disponibile'
        : <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <Space style={{alignItems: 'center'}}>
            <Typography.Title level={5} style={{marginBottom: 0}}>
              {team.codice}
            </Typography.Title>
            <Tag style={{
              ...TEAM_COLORS[TEAM_TYPE_ARRAY[team.tipo]]
            }}>{TEAM_TYPE_ARRAY[team.tipo]}</Tag>
          </Space>
          <Button onClick={() => open(
            'Dettagli team',
            TeamDetail,
            {id: team.id}
          )} icon={<InfoCircleOutlined/>}></Button>
          <Drawer closeAll={closeAll} />
        </div> 
    }
  </div>
}

const TeamAssegnati = observer(({id, openDrawer}) => {
  const assegnati = cm.controllers.teams.list
    .filter(team => team)
    .filter(team => team.assegnatoA === id);

  return <List 
    dataSource={assegnati}
    renderItem={(item) => <List.Item>
      <SingleTeam item={item} openDrawer={openDrawer}/> 
    </List.Item>}

  />
  
}); 

const SegnalazioniDetail = observer(({id, close, closeAll}) => {
  const [segnalazione, setSegnalazione] = useState(null)
  const [Drawer, open] = useDrawer();
  const [{show:openModal}, Modal] = useModalForm(assignTeam);
  const [resolving, setResolving] = useState(false);

  
  const resolve = useCallback(async () => {
    try {
      setResolving(true);
      await cm.controllers.segnalazioni.resolve(id);
    } catch (e) {
      message.error('Errore durante la risoluzione')
    } finally {
      setResolving(false);
    }
  }, [id]);

  
  useEffect(() => {
    let r;
    reaction(
      () => cm.controllers.segnalazioni.list,
      async (list, reaction) => {
        r = reaction; 
        const result = await cm.controllers.segnalazioni.get(id);
        setSegnalazione(result);
      }
    )

    return () =>  {
      if (r) {
        r.dispose();
      }
    }
  }, []);


  
  useEffect(() => {
    const load = async () => {
      try {
        const result = await cm.controllers.segnalazioni.get(id);
        setSegnalazione(result);
      } catch (e) {
        console.error(e);
        message.error('Errore caricamento segnalazione')
      }
    }
    if (id) {
      load()
    }
  }, [id]);

 

  return !segnalazione
    ? <LoadingOutlined />
    : <><Descriptions bordered column={2}>
    <Descriptions.Item label="Codice" span={1}>
      <Tag style={{
        ...SEGNALAZIONI_COLORS[segnalazione.codice]
      }}>
        {segnalazione.codice}
      </Tag>
    </Descriptions.Item>
    <Descriptions.Item label="Risolto" span={1}>
      {
        segnalazione.resolved === true
      ? <Space><CheckCircleOutlined /> Sì <small>{new Date(segnalazione.resolvedAt).toLocaleString()}</small></Space>
          : <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>

            <Space>
                <CloseCircleOutlined /> No   
            </Space>
            <Button loading={resolving} type="primary" icon={<CheckCircleOutlined />} onClick={resolve}>Risolvi</Button>
          </div> 
      }
    </Descriptions.Item>
    <Descriptions.Item span={2} label="Data creazione">
      {new Date(segnalazione.createdAt).toLocaleString()}
    </Descriptions.Item>
    {
      segnalazione.adminIntervention
        ? <Descriptions.Item label="Intervento richiesto" span={2}>
          <strong style={{display: 'block', marginBottom: '10px'}}>{segnalazione.adminIntervention}</strong>
          {
            segnalazione.codice === 'rosso' && segnalazione.resolvedAt && segnalazione.resolved === false
              ? <Button onClick={() => resolve(segnalazione.id)} loading={resolving} icon={<CheckCircleOutlined />} type="primary">Risolvi codice rosso</Button>
              : ''
          }
        </Descriptions.Item>
        : null
    }
    {
      segnalazione.tipo
        ? <Descriptions.Item label="Tipologia" span={1}>
          {segnalazione.tipo}
        </Descriptions.Item>
        : null
    }
    {
      segnalazione.dinamica
        ? <Descriptions.Item label="Dinamica" span={1}>
          {segnalazione.dinamica}
        </Descriptions.Item>
        : null
    }
    {
      segnalazione.coords
        ? <Descriptions.Item label="Coordinate" span={1}>
          <Space direction="vertical">
            <span>Latitude: {segnalazione.coords.lat} </span>
            <span>Longitude: {segnalazione.coords.lng}</span>
          </Space>
        </Descriptions.Item>
        : null
    }
    {
      segnalazione.createdBy
        ? <Descriptions.Item label="Creato da" span={2}>
            {
              segnalazione.createdByUser
                ? <SingleUser id={segnalazione.createdByUser} closeAll={closeAll} />
                : null
            }
            <Divider plain orientation="left" type="horizontal" >membro del team</Divider>
            <TeamDetails id={segnalazione.createdBy} closeAll={closeAll}/>
          </Descriptions.Item>
        : null
    }

    <Descriptions.Item label="Coinvolti" span={2}>
      {
        <List 
          dataSource={segnalazione.coinvolti}
          renderItem={(item => {
            return <List.Item>
              <div>
                <Space direction="vertical">
                  <strong style={{textTransform: 'capitalize'}}>{item.tipologia}</strong>
                  {
                    item.bollo
                      ? <Space>Bollo: <Tag>{item.bollo}</Tag></Space>
                      : null
                  }
                  {
                    item.targa
                      ? <Space>Targa: <Tag>{item.targa}</Tag></Space>
                      : null
                  }
                  {
                    item.numeroGara
                      ? <Space>Numero di gara: <Tag>{item.numeroGara}</Tag></Space>
                      : null
                  }
                </Space>
                
              </div>
            </List.Item>
          })}
        />
      }
    </Descriptions.Item>
    {
      segnalazione.note
        ? <Descriptions.Item label="Note aggiuntive" span={2}>
          {segnalazione.note}
        </Descriptions.Item>
        : null
    }
    {
      segnalazione.foto && segnalazione.foto.length > 0
        ? <Descriptions.Item label="Foto" span={2}>
          <Space>
            {
              segnalazione.foto
                .map((item, idx) => <S3Image style={{
                  cursor: 'pointer'
                }} key={`foto-segnalazione-${idx}`} src={item} width={60} />)
            }
          </Space>
        </Descriptions.Item>
        : null
    }
    {
      !segnalazione.resolved
        ? <Descriptions.Item label="Team assegnati" span={2}>
          <TeamAssegnati openDrawer={open} id={segnalazione.id}  />
          <Button onClick={() => openModal({}, 'Assegna team', {coords: segnalazione.coords, segnalazione: segnalazione.id, openDrawer:open}, '800px')} block icon={<PlusCircleOutlined />} >Assegna team</Button>
        </Descriptions.Item>
        : null
    }
    <Descriptions.Item label="Note direzione" span={2}>
      <Form initialValues={{
        noteDirezione: segnalazione.noteDirezione
      }} onFinish={async (values) => {
        try {
          await cm.controllers.segnalazioni.updateNote(segnalazione.id, values.noteDirezione);
          message.success('Note segnalazione aggiornate');
        } catch (e) {
          message.error('Errore durante l\'aggiornamento delle note')
        }
      }}>
        <Form.Item name="noteDirezione" value={segnalazione.noteDirezione}>
          <TextArea />
        </Form.Item>
        <Button type="primary" htmlType="submit" >Aggiorna note</Button>
      </Form>
    </Descriptions.Item>
  </Descriptions>
 

 
  <div style={{
    height: '300px'
  }}>
    <Maps
      bootstrapURLKeys={{ key: 'AIzaSyDtTtWDz9AynV0hBW2_WVZqfKOaoHpxrsc' }}
      defaultCenter={segnalazione.coords}
      defaultZoom={11}
    >
      <MarkerSegnalazioni codice={segnalazione.codice} {...segnalazione.coords} />
    </Maps>
  </div>
  <footer style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '20px'
  }}>
    <small>Creato il {new Date(segnalazione.createdAt).toLocaleString()}</small>
    <small>Ultimo aggiornamento il {new Date(segnalazione.updatedAt).toLocaleString()}</small>
  </footer>
   <Drawer close={close} closeAll={closeAll}/>
   {Modal}
  </>
});

export default SegnalazioniDetail;