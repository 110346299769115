import TwilioChat from 'twilio-chat'
import { decorate, observable, reaction } from 'mobx';

import Controller from '../utils/Controller';
import { notification } from 'antd';


class Chat extends Controller {
  constructor(options, manager) {
    super(options, manager);
    this.client = null;
    this.username = '';
    this.channels = observable.map({});
    this.open = false;
    this.currentChannel = 'general';
    reaction(
      () => this.currentChannel,
      async () => {
        this.channels.get(this.currentChannel).unread = 0;
        await this.channels.get(this.currentChannel).instance.setAllMessagesConsumed()
      }
    )
    reaction(
      () => this.open,
      async () => {
        if (this.open) {
          const current = this.channels.get(this.currentChannel);
          if (current) {
            await current.instance.setAllMessagesConsumed();
            current.unread = 0;
          }
        }
      }
    )
  }
  
  get name() {
    return 'chat'
  }

  get priority() {
    return 1006;
  }

  async updateAttributes(channelName, attributes) {
    const channel = this.channels.get(channelName);
    if (channel) {
      channel.instance.updateAttributes(attributes);
    }
  }

  async getSession () {
    const session = await this.manager.controllers.api.get('/chat/token');
    this.username = session.identity;
    return session;
  }

  async refreshToken() { 
    const session = await this.getSession()
    if (this.client) {
      this.client.updateToken(session.token);
    }
  }

  async addChannel(channel) {
    const messages = await channel.getMessages();
    const unread = await channel.getUnconsumedMessagesCount();
    this.channels.set(channel.uniqueName, {
      instance: channel,
      messages,
      unread: unread === null ? 0 : unread
    });
    channel.on('messageAdded', this.onMessage(channel.uniqueName));
  }

  async onRemoveChannel(channel) {

    notification.warning({
      message: 'Rimosso canale',
      description: `Il canale di chat ${channel.uniqueName} è stato chiuso`
    });
    this.channels.delete(channel.uniqueName);
  }

  onMessage(channelName) {
    return async (message) => {
      const c = this.channels.get(channelName);
      if (c.instance.attributes.visible === false) {
        this.updateAttributes(channelName, {
          visible: true
        })
      } 
      if (channelName !== this.currentChannel) {
        notification.open({
          message: 'Nuovo messaggio di chat',
          description: `Nuovo messaggio di chat nel canale ${channelName}`
        });
        c.unread += 1;
      }
      c.messages.items = [...c.messages.items, message];
    }
  }

  onTypingStart(channelName) {
    return (member) => {
      const c = this.channels.get(channelName);
      c.typing = member;
    }
  }

  onTypingEnd(channelName) {
    return (member) => {
      const c = this.channels.get(channelName);
      c.typing = null;
    }
  }
  
 async onChannelUpdate(obj) {
    const {channel} = obj;
    const messages = await channel.getMessages();
    const unread = await channel.getUnconsumedMessagesCount();
    this.channels.delete(channel.uniqueName);
    this.channels.set(channel.uniqueName, {
      instance: channel,
      messages,
      unread: unread === null ? 0 : unread
    });
  }

  async connect() {
    const data = await this.getSession();
    this.client = await TwilioChat.create(data.token);
    this.client.on('tokenAboutToExpire', this.refreshToken.bind(this));
    this.client.on('tokenExpired', this.refreshToken.bind(this));
    this.client.on('channelAdded', this.addChannel.bind(this));
    this.client.on('channelRemoved', this.onRemoveChannel.bind(this));
    this.client.on('channelUpdated', this.onChannelUpdate.bind(this));

    const subscribedChannels = await this.client.getSubscribedChannels();

    let isMemberOfGeneral = false;

    for (let i = 0; i < subscribedChannels.items.length; i++) {
      const channel = subscribedChannels.items[i];

      if (channel.uniqueName === 'general') {
        isMemberOfGeneral = true;
      }


      channel.on('typingStarted', this.onTypingStart(channel.uniqueName));
      channel.on('typingEnded', this.onTypingEnd(channel.uniqueName));
    }

    if (!isMemberOfGeneral) {
      try {
        await this.joinChannel('general');
      } catch (e) {
        try {
          await this.createChannel('general');
          await this.joinChannel('general');
        } catch (e) {

        }
      }
    }
  
  }

  async getChannelMembers(channelName) {
    const ch = this.channels.get(channelName);
    if (ch) {
      return await ch.instance.getMembers()
    } else {
      return [];
    }
  }

  async joinChannel(name) {
    if (this.client) {
      const channel = await this.client.getChannelByUniqueName(name);
      await channel.join();
      
    }
  }

  async sendMessage(message) {
    if (this.channels.has(this.currentChannel)) {
      const instance = this.channels.get(this.currentChannel).instance;
      await instance.sendMessage(message);
      instance.setAllMessagesConsumed();
    }
  }

  async addToChannel(channel, member) {
    if (this.channels.has(channel)) {
      const instance = this.channels.get(channel).instance;
      await instance.add(member);
    }
  }

  async userExists (identity) {
    try {
      await this.client.getUser(identity)
      return true;
    } catch (e) {
      return false;
    }
  }

  async removeMember(channel, identity) {
    if (this.channels.has(channel)) {
      const ch = this.channels.get(channel);
      await ch.instance.removeMember(identity)
    }
  }

  async createChannel(name) {
    if (this.client) {
      await this.client.createChannel({
        uniqueName: name,
        friendlyName: name
      })
    }
  }

  async removeChannel(name) {
    if (name === 'general') return
    if (this.client) {
      const channel = this.channels.get(name);
      if (channel.instance.isPrivate) {
        this.updateAttributes(name, {
          visible: false
        })
      } else {
        await channel.instance.delete();
      }
    }
    if (this.currentChannel === name) {
      this.currentChannel = 'general'
    }
  }

  
}

export default decorate(Chat, {
  channels: observable,
  username: observable,
  open: observable,
  currentChannel: observable
});