import React, { useMemo } from 'react';
import { Input, Button, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

const useTableSearch = (dataIndex, {hasRender, searchFunction} = {hasRender: false}) => {
  const getColumnSearch = useMemo(() => {
    let input;
    let key = '';
    return ({
      filterDropdown: ({ 
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) => {
                
        return (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              input = node;
            }}
            placeholder={`Cerca ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
            }}
            onPressEnter={() => {
              confirm();
              key = selectedKeys[0]; 
            }}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => {
                confirm();
                key = selectedKeys[0]; 
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Cerca
            </Button>
            <Button onClick={() => {
              clearFilters();
              key = ''; 
            }} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      )},
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter:  (value, record) => searchFunction
        ?  searchFunction(value, record)
        : (value, record) => dataIndex
          .split('.')
          .reduce((prev, curr) => prev[curr], record)
          .toString()
          .toLowerCase()
          .includes(typeof value === 'string' ? value.toLowerCase() : value),
        
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => input.select());
        }
      },
      ...(hasRender ? {} : {
        render: (text) => {
          return key.length > 0 
            ? <Highlighter
              searchWords={[key]}
              textToHighlight={text}
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              autoEscape
            />
            : text
          }
      })    
    })
  }, [dataIndex]);

  return getColumnSearch;
};

export default useTableSearch;