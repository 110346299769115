import React, { createContext, useContext } from 'react';

const ScrollContext = createContext(null);

export const ScrollProvider = (props) => {
  return <ScrollContext.Provider {...props}>
    {props.children}
  </ScrollContext.Provider>
};

const useScroll = () => useContext(ScrollContext);

export default useScroll;