import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Layout,  Typography, Row, Col, Anchor, Divider, Card, Space, Button} from 'antd';
import moment from 'moment';


import UpdateEventForm from '../../../../components/eventi/update-form';
import GestioneTappe from '../../../../components/eventi/gestione-tappe';
import GestioneTeams from '../../../../components/eventi/gestione-teams';
import GestionePartecipanti from '../../../../components/eventi/gestione-partecipanti';
import useScroller from '../../../../hooks/useScroller';
import scrollToAnimation from '../../../../utils/scrollToAnimation';
import Attivazione from '../../../../components/eventi/attivazione';
import EliminazioneEvento from '../../../../components/eventi/eliminazione';
// import CreateTappa from '../../../../components/Forms/CreateTappa';

import cm from '../../../../controllers'
import { Link } from '@reach/router';
import { DashboardOutlined } from '@ant-design/icons';

const { Content } = Layout;


const Impostazioni = () => {

  const scroller = useScroller();
  const ref = useRef();

  useEffect(() => {
    
    if (scroller && ref.current) {
      const affix = ref.current;

      const top = affix.offsetTop;
      affix.style.transition = 'transform 300ms ease-in-out;'
      function onScroll(e) {
        if (scroller.scrollTop > top) {
          affix.style.transform = `translateY(${scroller.scrollTop - top + 10}px)`;
        } else {
          affix.style.transform = `translateY(0)`
        }
      };
      scroller.addEventListener('scroll', onScroll);
      return () => {
        scroller.removeEventListener('scroll', onScroll);
      }
    }

  }, [scroller])

  const onClick = useCallback((e, link) => {
    e.preventDefault();
    scrollToAnimation(scroller, link.href);
  }, [scroller]);

  return <Layout>
    <Content>
      <Row>
        <Col span={20} style={{
          padding: '40px'
        }}>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography.Title style={{color: 'white'}}>Impostazioni</Typography.Title>
          {
            cm.controllers.eventi.current &&
            moment().isBetween(moment(cm.controllers.eventi.current.startDate), moment(cm.controllers.eventi.current.endDate).add(1, 'days'))
              ? <Link to={`/dashboard/eventi/${cm.controllers.eventi.current.id}/gestione`}>
                <Button icon={<DashboardOutlined />}>
                  Gestisci
                </Button>
              </Link>
              : null
          }
          </div>
          <Space size={40} direction="vertical" style={{width: '100%'}}>
            <Card title="Impostazioni principali"  id="impostazioni-generali">
              <UpdateEventForm />
            </Card>
            <Card title="Attivazione e archiviazione evento">
              <Attivazione />
            </Card>
            <Card title="Gestione Tappe e Percorsi" id="tappe">
              <GestioneTappe />
            </Card>
            <GestioneTeams />
            <GestionePartecipanti />
            <Card id="delete" title="Elimina evento">
              <EliminazioneEvento />
            </Card>
          </Space>
        </Col>
        <Col span={4} style={{
          padding: '40px'
        }}>
          <div ref={ref} style={{
            marginTop: '80px',
            padding: '20px',
            background: 'white',
            borderRadius: '2px'
          }} >
            <Anchor affix={false} onClick={onClick} >
              <Anchor.Link 
                href="#impostazioni-generali"
                title="Impostazioni generali"
              />
              <Anchor.Link 
                href="#attiva-archivia"
                title="Attivazione e archiviazione evento"
              />
              <Anchor.Link 
                href="#tappe"
                title="Gestione Tappe e Percorsi"
              />
              <Anchor.Link
                href="#teams"
                title="Gestione Teams"
              />
              <Anchor.Link
                href="#partecipanti"
                title="Lista partecipanti"
              />
              <Anchor.Link 
                href="#delete"
                title="Elimina evento"
              />
            </Anchor>

          </div>
        </Col>
      </Row>
    </Content>
  </Layout>
};

export default Impostazioni;