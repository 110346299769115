import React, { useEffect, useState } from 'react';
import { Layout, PageHeader, Card, Table, Button, Empty, Space } from 'antd';
import { PlusCircleOutlined, SettingTwoTone, StopOutlined } from '@ant-design/icons';
import { Link } from '@reach/router';
import styled from 'styled-components';
import LogRocket from 'logrocket';

import Container from '../../../components/Container';
import useModalForm from '../../../hooks/useModalForm';
import createEvento from '../../../components/Forms/CreateEvent';
import User from '../../../components/User';
import withS3Src from '../../../hoc/withS3Src';

import cm from '../../../controllers';
import EventiList from '../../../components/eventi/list';
import Reports from '../../../components/eventi/reports';


LogRocket.init('54ofhr/gta-dashboard');

const {Content} = Layout;


const Header = styled.header`
  display: flex;
  align-items:center;
  justify-content: space-between;
  margin-bottom: 10px;
`;


const Eventi = () => {
  const [loading, setLoading] = useState(false);
  
  const [{show, close}, Modal] = useModalForm(createEvento, 'Crea evento');



  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      await cm.controllers.eventi.fetch();
      setLoading(false);
    }
    if (cm.controllers.eventi.fetched === false) {
      fetch();
    }
  }, [])
 
  return <Layout style={{width: '100%'}}>
    <PageHeader
      title="Eventi" 
      subTitle="Gestisci i prossimi eventi"
      extra={
        <User />
      }
    />
    <Content style={{paddingTop: '40px'}}>
      <Container>
        <Space direction="vertical" style={{width: '100%'}} size={40}>
          <div>  
            <h1 style={{color: 'white'}}>In corso</h1>          
            <EventiList inCorso loading={loading} />
          </div>
          <div >
            <Header>
              <h1 style={{color: 'white'}}>Eventi Programmati</h1>
              <Button onClick={() => show(null, 'Crea evento')} type="primary" icon={<PlusCircleOutlined />}>
                Aggiungi Evento
              </Button>
            </Header>
            <EventiList inCorso={false} loading={loading}/>
            <h1 style={{color: 'white'}}>Reports</h1>
            <Reports />
          </div>
        </Space>
      </Container>
    </Content>
    {Modal}
  </Layout>
};

export default Eventi;

