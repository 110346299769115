import React, { useState } from 'react';
import {Form, Input, Button, Space} from 'antd';
import Title from 'antd/lib/typography/Title';

import cm from '../../controllers';
import { navigate } from '@reach/router';

const Login = ({showError, clearError}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false)

  return <Form
    form={form} 
    labelCol={{lg: 24}} 
    wrapperCol={{lg:24}} 
    labelAlign="left"
    onFinish={async (values) => {
      try {
        clearError();
        setLoading(true);
        await cm.controllers.auth.login(values.username, values.password);
        if (
          cm.controllers.auth.user.challengeName &&
          cm.controllers.auth.user.challengeName === 'NEW_PASSWORD_REQUIRED'
        ) {
          navigate('/auth/challenge/NEW_PASSWORD_REQUIRED'); 
        } else {
          navigate('/');
        }
      } catch(e) {
        switch (e.code) {
          case 'UserNotFoundException':
            showError('Credenziali invalide')
            break;
          case "NotAuthorizedException":
            showError('Credenziali invalide');
            break;
          default:
            showError('Errore sconosciuto durante l\'autenticazione');
            break;
        }
      } finally {
        setLoading(false)
      }
    }}
  >
    <Title level={2} style={{textAlign:'center'}}>Accedi</Title>
    
    <Form.Item 
      name="username" 
      label="Email" 
      hasFeedback
      rules={[
        {required: true, message: 'Campo necessario'},
        {type: 'email', message: 'Email invalida'}
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item 
      name="password" 
      required 
      label="Password" 
      hasFeedback    
      rules={[
        {required: true, message: 'Campo necessario'}
      ]}
    >
      <Input.Password />
    </Form.Item>
    <Form.Item wrapperCol={{lg:24}} labelCol={{lg:0}} style={{textAlign: 'right'}}>
      <Space>
        <Button type="link" onClick={() => {
          const username = form.getFieldValue('username');
          if (username) {
            navigate(`/auth/forgot?email=${username}`)
          } else {
            navigate(`/auth/forgot`)
          }
        }}>Password dimenticata ?</Button>
        <Button 
          loading={loading}
          type="primary"
          htmlType="submit"
        >
          Invia
        </Button>
      </Space>
    </Form.Item>
  </Form>
};

export default Login