import { List, Tag } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import Widget from '../../dashboard/widget';

import cm from '../../../controllers';
import { SEGNALAZIONI_COLORS } from '../../../controllers/Segnalazioni';
import SegnalazioniDetail from '../detail';

const SingleSegnalazione = ({item, openDrawer}) => {
  return <button 
    style={{
      background: 'transparent',
      width: '100%',
      border: 0,
      padding: '10px 20px',
      textAlign: 'left',
      cursor: 'pointer'
    }}
    onClick={() => {
      openDrawer(
        `Dettagli segnalazione`,
        SegnalazioniDetail,
        {
          id: item.id
        }
      );
    }}
  >
    <strong>{item.adminIntervention}</strong>
    <footer style={{
      marginTop: '10px',
      display: 'flex',
      justifyContent: 'space-between'
    }}>
      <span>{new Date(item.createdAt).toLocaleTimeString()}</span>
      <Tag
        style={{
          ...SEGNALAZIONI_COLORS[item.codice]
        }}
      >{item.codice}</Tag>
    </footer>
  </button>
}

const RequiringAttention = observer((props) => {
  return <Widget {...props} noPadding >
    <List
      style={{
        overflowY: 'auto',
        padding: '0 0 60px 0',
        height: '100%'
      }}
      dataSource={
        cm.controllers.segnalazioni.list
          .filter(item => !item.resolved)
          .filter(item => item.adminIntervention)
      }
      renderItem={(item) => <List.Item>
        <SingleSegnalazione item={item} openDrawer={props.openDrawer}/>
      </List.Item>}
    />
  </Widget>
});

export default RequiringAttention;