import { Col, Row, Form, Space, DatePicker, Button, Empty, List, Typography, message, Popconfirm, Input, Upload } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Maps from 'google-map-react';
import { CheckCircleOutlined, DeleteOutlined, LoadingOutlined, MinusCircleOutlined, NodeIndexOutlined, PlusOutlined, RightOutlined, UploadOutlined } from '@ant-design/icons';

import cm from '../../controllers';
import { observer } from 'mobx-react';
import { reaction } from 'mobx';
import { Storage } from 'aws-amplify';

const ListItem = styled(List.Item)`
  cursor:pointer;
  background: ${props => props.selected ? '#FE7D07' : 'transparent'};
  padding: 20px;
`;

const ListaTappe = observer(({onSelect, selected}) => {

  useEffect(() => {
    let r;
    reaction(
      () => cm.controllers.eventi.current, 
      async (current, reaction) => {
        r = reaction
        if (cm.controllers.eventi.current && cm.controllers.tappe.fetched === false) {
          try {
            await cm.controllers.tappe.fetch();
          } catch (e) {
            message.error('Impossibile caricare le tappe')
          } 
        }
      },
      {fireImmediately: true}
    )
    return () => r.dispose();
  }, [])

  
  return cm.controllers.tappe.list.length > 0 
    ? <List 
      style={{marginBottom: '20px'}}
      dataSource={
        cm.controllers.tappe.list
          .slice()
          .sort((a,b) => new Date(a.data) - new Date(b.data))
      }
      renderItem={(tappa, idx) => (
        <ListItem
          style={{cursor: 'pointer'}}
          onClick={() => onSelect(tappa.id)}
          selected={selected === tappa.id}
        >
          <span>
            Tappa {idx + 1}: <strong>{new Date(tappa.data).toLocaleDateString()}</strong> 
          </span>
          <RightOutlined />
        </ListItem>
      )}
    />
    : <Empty description="Nessuna tappa" style={{marginBottom: '20px'}}/>
});


const Settori = observer(({selected, onDelete}) => {
  const [tappa, setTappa] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [visibleForm, setVisibleForm] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [removingSector, setRemovingSector] = useState(-1);

  const [form] = Form.useForm();
  
  const onCancel = useCallback(() => {
    setVisibleForm(false);
    form.resetFields();
  }, []);

  const onClick = useCallback(() => {
    setVisibleForm(true);
  }, []);

  const onFinish = useCallback(async (id, values) => {
    try {
      setFormLoading(true)
      message.loading({
        content: 'Caricamento file in corso',
        key: 'file'
      })
      const {file} = values.file;
      const extension = file.name.split('.').slice(-1);

      const res = await Storage.put(`${file.uid}.${extension}`, file);

      message.success({
        content: 'File caricato con successo',
        key: 'file'
      });
      await cm.controllers.tappe.createSector(id, {
        nome: values.nome,
        file: res.key
      });
    } catch (e) {
      console.dir(e);
      message.error('Errore durante la creazione del settore')
    } finally {
      setFormLoading(false)
      setVisibleForm(false);
      form.resetFields();
    }
  }, []);

  useEffect(() => {
    let r;
    const load = async () => {
      const res = await cm.controllers.tappe.get(selected);
      setTappa(res);
    }
    reaction(
      () => cm.controllers.tappe.list,
      async (list, reaction) => {
        r = reaction;
        if (selected) {
          await load();
        } else {
          setTappa(null);
        }
      }, {fireImmediately: true}
    );

    return () => {
      if (r && r.dispose) r.dispose();
    }
  }, [selected]);
  
  const remove = useCallback(async () => {
    try {
      setRemoving(true);
      await cm.controllers.tappe.delete(tappa.id);
      if (onDelete) {
        onDelete();
      }
    } catch (e) {
      message.error('Errore durante l\'eliminazione della tappa')
    }
    finally {
      setRemoving(false)
    }
  }, [tappa])


  const removeSector = useCallback(async (i) => {
    try {
      setRemovingSector(i);
      await cm.controllers.tappe.deleteSector(tappa.id, i);
    } catch (e) {
      console.dir(e);
      message.error('Errore durante l\'eliminazione del percorso')
    }
    finally {
      setRemovingSector(-1)
    }
  }, [tappa]);



  return !tappa
    ? 'Seleziona una tappa per impostare il percorso'
    : <div>
      <header style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '40px'
      }}>
        <span>Tappa del {new Date(tappa.data).toLocaleDateString()}</span>
        <Popconfirm
          title="Sei sicuro?"
          onConfirm={remove}
          okText="Sì"
          cancelText="No"
        >
          <Button loading={removing} danger icon={<DeleteOutlined />}>Elimina</Button>
        </Popconfirm>
      </header>
      <div>
        <Typography.Title level={4}>Lista percorsi</Typography.Title>
        {
          !tappa.settori || tappa.settori.length === 0
            ? <Empty description="Nessun percorso trovato" style={{marginBottom: '40px'}}/>
            : <List 
              style={{marginBottom: '40px'}}
              dataSource={tappa.settori}
              renderItem={(item, idx) => (
                <List.Item>
                  <Space>
                    <NodeIndexOutlined /> <strong>{item.nome}</strong> 
                    <Popconfirm
                      title="Sei sicuro?"
                      onConfirm={() => removeSector(idx)}
                      okText="Sì"
                      cancelText="No"
                    >
                      <Button type="link" loading={removingSector === idx}>Elimina</Button>
                    </Popconfirm>
                  </Space>
                </List.Item>
              )}
            />
        }
      </div>
      <div>
      {
        visibleForm
          ? <Form form={form} onFinish={(values) => onFinish(tappa.id, values)}>
            <Space style={{display: 'flex', marginBottom: 8 }} align="start">
              <Form.Item name="nome" label="Nome" required={[{required: true, message: "Campo necessario"}]}>
                <Input />
              </Form.Item>
              <Form.Item name="file" required label="File KMZ">
                <Upload
                  beforeUpload={() => false}
                  multiple={false}
                >
                  <Button icon={<UploadOutlined />}>Carica File</Button>
                </Upload>
              </Form.Item>
              <Button loading={formLoading} type="primary" htmlType="submit"><CheckCircleOutlined /></Button>
              <Button onClick={onCancel}><MinusCircleOutlined /></Button>
            </Space>
          </Form>
          : <Button block icon={<PlusOutlined />} onClick={onClick}>
            Aggiungi percorso
          </Button> 
      }
      </div>
    </div>
})


const Mappa = observer(({selected}) => {
  const [google, setGoogle] = useState(null);
  const [tappa, setTappa] = useState(null);
  const layers = useRef(null)
  
  useEffect(() => {
    let r;
    const load = async () => {
      const result = await cm.controllers.tappe.get(selected);
      setTappa(result);
    }
    reaction(
      () => cm.controllers.tappe.list,
      async (list, reaction) => {
        r = reaction;
        if (selected) {
          await load();
        } else {
          setTappa(null);
        }
      },
      {fireImmediately: true}
    );

    return () => {
      if (r && r.dispose) r.dispose();
    }
  }, [selected])

  useEffect(() => {
    let r;

    reaction(
      () => cm.controllers.tappe.list,
      (list, reaction) =>{
        r = reaction;
        if (layers.current) {
          layers.current.forEach(layer => layer.setMap(null))
        }
        layers.current = []; 
        if (!tappa || !tappa.settori || tappa.settori.length === 0 || !google) return;
       
        const config = cm.controllers.config.get('amplify').Storage;
        const {settori} = tappa;
        settori
          .map(settore => {
            return `https://s3.${config.AWSS3.region}.amazonaws.com/${config.AWSS3.bucket}/public/${settore.file}`;
          })
          .forEach((url) => {
            layers.current.push(
              new google.maps.KmlLayer(url, {
                map: google.map,
                suppressInfoWindows: false,
                preserveViewport: false,
              })
            )
          })
      }, 
      {fireImmediately: true} 
    )

    return () => r.dispose();     

  }, [tappa, google])

  const handleApiLoaded = useCallback((googleMaps) => {
    setGoogle(googleMaps);
  }, [])

  return <Maps
    bootstrapURLKeys={{ key: 'AIzaSyDtTtWDz9AynV0hBW2_WVZqfKOaoHpxrsc' }}
    defaultCenter={{lng: 10.2157, lat: 45.5318 }}
    defaultZoom={11}
    yesIWantToUseGoogleMapApiInternals
    onGoogleApiLoaded={handleApiLoaded}
    style={{
      minHeight: '500px'
    }}
  />
})

const GestioneTappe = () => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [selected, setSelected] = useState(false);

  const onClick = useCallback(() => {
    form.resetFields();
    setVisible(true);
  }, [])

  const onCancel = useCallback(() => {
    form.resetFields();
    setVisible(false);
  });
  
  const onFinish = useCallback(async (values) => {
    try {
      setFormLoading(true);
      await cm.controllers.tappe.create(values);
    } catch (e) {
      message.error('Errore durante la creazione della tappa');
    } finally {
      setFormLoading(false)
      setVisible(false);
      form.resetFields();
    }
  })

  const onSelect = useCallback(async (selected) => {
    setSelected(selected);
  })

  const onDelete = useCallback(() => {
    setSelected(null);
  })

  return <Row style={{padding: '50px'}} gutter={50}>
    <Col span={8}>
      <Mappa selected={selected} />
    </Col>
    <Col span={6}>
      <Typography.Title level={3}>Tappe</Typography.Title>
      <ListaTappe onSelect={onSelect} selected={selected} />
      {
        visible
          ? <Form form={form} onFinish={onFinish}>
            <Space style={{display: 'flex', marginBottom: 8 }} align="start">
              <Form.Item name="data" label="Data nuova tappa" rules={[{required: true}]}>
                <DatePicker />
              </Form.Item>
              <Button loading={formLoading} type="primary" htmlType="submit"><CheckCircleOutlined /></Button>
              <Button onClick={onCancel}><MinusCircleOutlined /></Button>
            </Space>
          </Form>
          : <Button block icon={<PlusOutlined />} onClick={onClick}>
            Aggiungi tappa
          </Button> 
      }
    </Col>
    <Col span={10}>
      <Typography.Title level={3}>Percorso tappa</Typography.Title>
      <Settori selected={selected} onDelete={onDelete}/>
    </Col>
  </Row>
};

export default (GestioneTappe);