import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, List, Space, Table, Tag } from 'antd';
import { reaction } from 'mobx';
import React, { useEffect, useMemo, useState } from 'react';
import { SEGNALAZIONI_COLORS } from '../../../controllers/Segnalazioni';
import useDrawer from '../../../hooks/useDrawer';
import SegnalazioniDetail from '../detail';

import cm from '../../../controllers';
import { TEAM_COLORS, TEAM_TYPE_ARRAY } from '../../../controllers/Teams';
import TeamDetail from '../../teams/detail';
import { observer } from 'mobx-react';


const TeamAssegnato = ({id, openDrawer}) => {
  const [teams, setTeams] = useState(null);

  useEffect(() => {
    let r;

    reaction(
      () => cm.controllers.teams.list,
      (lista, reaction) => {
        r = reaction;
        setTeams(lista.filter(t => t.assegnatoA === id));
      },
      {
        fireImmediately: true
      }
    )

    return () => r.dispose();
  }, [id])

  return !teams
    ? <LoadingOutlined />
    : teams.length === 0 
      ? 'Nessun team assegnato'
      : <List 
      dataSource={teams}
      renderItem={(item) => <List.Item>
        <div>
          <Space>
            {item.codice} 
            <Tag style={{
              ...TEAM_COLORS[TEAM_TYPE_ARRAY[item.tipo]]
            }}>{TEAM_TYPE_ARRAY[item.tipo]}</Tag>
          </Space>
          </div>
          <Button icon={<InfoCircleOutlined />} onClick={() => {
            openDrawer(
              'Dettagli team',
              TeamDetail,
              {id: item.id}
            )
          }}></Button>
      </List.Item>}
      
    />

}

const SegnalazioniTable = observer(({ids, close}) => {
  const [Drawer, open] = useDrawer();

  const segnalazioni = cm.controllers.segnalazioni.list
    .filter(item => (ids || []).indexOf(item.id) !== -1)

  const columns = useMemo(() => [{
    title: 'Creazione',
    dataIndex: 'createdAt',
    render: item => new Date(item).toLocaleString(),
    sorter: {
      compare: (a, b) => new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime() ? -1 : 1,
      multiple: 1
    } 
  }, {
    title: 'Codice',
    dataIndex: 'codice',
    render: (item) => <Tag style={{
      ...SEGNALAZIONI_COLORS[item]
    }}>{item}</Tag> ,
    sorter: {
      compare: (a, b) => a.codice > b.codice ? -1 : 1,
      multiple: 1
    } 
  }, {
    title: 'Risolto',
    dataIndex: '',
    render: item => item.resolved
      ? <Space><CheckCircleOutlined /> Sì <small>{new Date(item.resolvedAt).toLocaleString()}</small></Space>
      : <Space><CloseCircleOutlined /> No</Space>,
    sorter: {
      compare: (a, b) => new Date(a.resolvedAt).getTime() > new Date(b.resolvedAt).getTime() ? -1 : 1,
      multiple: 1
    } 
  }, {
    title: 'Coinvolti',
    dataIndex: 'coinvolti',
    render: (item) => <ul style={{margin: 0}}>
      {item.map(coinvolto => <li key={`${coinvolto.tipologia}-${coinvolto.numeroGara}-${coinvolto.targa}-${coinvolto.bollo}`}>
        {coinvolto.tipologia}: 
        {coinvolto.numeroGara ? <Tag>{coinvolto.numeroGara}</Tag> : null}
        {coinvolto.targa ? <Tag>{coinvolto.targa}</Tag> : null}
        {coinvolto.bollo ? <Tag>{coinvolto.bollo}</Tag> : null}
      </li>)}
    </ul>,
    sorter: {
      compare: (a, b) => a.coinvolti.length > b.coinvolti.length ? -1 : 1,
      multiple: 1
    } 
  }, {
    title: 'Team assegnato',
    render: (item) => <TeamAssegnato openDrawer={open} id={item.id} />
  },{
    title: 'Intervento richiesto',
    dataIndex: 'adminIntervention',
    render: item => item ? <strong>{item}</strong> : 'Nessun intervento richiesto'
  }, {
    title: '',
    dataIndex: 'id',
    render: (item) => {
      return <Button onClick={() => open(
        `Dettagli segnalazione`,
        SegnalazioniDetail,
        {
          id: item
        }
      )} icon={<InfoCircleOutlined />}>
        Informazioni
      </Button>
    }
  }], []);

  return <><Table
    dataSource={segnalazioni.sort((a, b) => {
      return new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime() ? -1 : 1;
    })}
    columns={columns}
    rowKey={item => item.id}
  /><Drawer close={close}/></>
});

export default SegnalazioniTable;