import { AlertOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { TEAM_COLORS, TEAM_TYPE_ARRAY } from '../../controllers/Teams';


const Marker = styled.div`
  display: flex;
  background:  ${props => props.colore ? props.colore.background : 'white'};
  justify-content: center;
  color: white;
  align-items: center;
  z-index:3;
  cursor: ${props => props.onClick ? 'pointer' : 'normal'};
  text-align:center;
  border-radius: 10px 10px 0 0;
  padding: 5px 15px 5px 15px;
`;



const MarkerSegnalazioni = ({tipo, id, onClick, lat, lng, codice}) => {
  const colore = useMemo(() => {
    const t = TEAM_TYPE_ARRAY[tipo];
    return TEAM_COLORS[`${t}`];
  }, [tipo])

  return <Marker colore={colore}  onClick={onClick} lat={lat} lng={lng} >
    <Space direction="vertical" size={2}>
      <span>
        <AlertOutlined style={{fontSize: '18px'}}/>  
      </span>
      {codice}
    </Space>
  </Marker>
}

export default MarkerSegnalazioni;