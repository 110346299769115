import { List, Tag } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import Widget from '../../dashboard/widget';

import cm from '../../../controllers';
import { TEAM_COLORS, TEAM_TYPE_ARRAY } from '../../../controllers/Teams';
import TeamDetail from '../detail';

const SingleTeam = ({item, openDrawer}) => {
  return <button style={{
    background: 'transparent',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    cursor: 'pointer'
  }} onClick={() => {
    openDrawer(`Dettagli team ${item.codice}`, TeamDetail, {id: item.id})
  }}>
    <span>{item.codice}</span>
    <Tag
      style={{
        ...TEAM_COLORS[TEAM_TYPE_ARRAY[item.tipo]]
      }}
    >{TEAM_TYPE_ARRAY[item.tipo]}</Tag>
  </button>
};

const TeamListWidget = observer(({title, openDrawer, rows, cols }) => {
  return <Widget title={title} rows={rows} cols={cols} noPadding>
    <List
      style={{
        overflowY: 'auto',
        padding: '20px',
        height: '100%'
      }}
      dataSource={cm.controllers.teams.list}
      renderItem={(item) => <List.Item>
          <SingleTeam item={item} openDrawer={openDrawer} />
        </List.Item>
      }
    />
  </Widget>
})

export default TeamListWidget;