import React from 'react';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

const Container = styled.div`
  display: flex;
  width: 100%; 
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const LoadingIcon = () => {
  return <Container>
    <LoadingOutlined style={{fontSize: '50px' }}/>
  </Container>
};

export default LoadingIcon;