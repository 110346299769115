import { Card } from 'antd';
import React from 'react';
import {omit} from 'lodash';

const Widget = (props) => {
  return <Card 
    style={{
      gridColumn: `span ${props.cols || 1}`,
      gridRow: `span ${props.rows || 1}`,
      borderRadius: '5px',
      overflow: 'hidden',
    }} 
    className={`${props.className ? props.className : ''} ${props.noPadding ? 'no-padding' : ''}`} 
    {...omit(props, ['noPadding','cols', 'rows', 'openDrawer'])}
  >
    {props.children}
  </Card>
};

export default Widget;