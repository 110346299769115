import Controller from '../utils/Controller';
import { decorate, computed, action, observable } from 'mobx';

class CTS extends Controller {
  constructor(options, manager) {
    super(options, manager);
    this.fetched = false;
    this.users = [];
  }
 
  get map() {
    return this.users
      .reduce((agg, curr) => {
        agg[curr.cognitoRef] = curr;
        return agg
      }, {});
  }

  get name() {
    return 'cts'
  }

  get priority() {
    return 1000;
  }

  async get(id) {
    if (typeof id ==='object') {
      id = id.id;
    }
    if (this.map[id]) {
      return this.map[id];
    } else {  
      const result = await this.manager.controllers.api.get(`/cts/${id}`);
      if (!result.Item) return;
      this.users = [...this.users, result.Item];
      return result.Item;
    }
  }

  async fetch () {
    const result = await this.manager.controllers.api.get('/cts');
    
    this.users = [...result.Items];
    this.fetched = true;
  }

  async delete(id) {
    const result = await this.manager.controllers.api.del(`/cts/${id}`);
    this.users = this.users.filter(user => user.cognitoRef !== result.Item.cognitoRef);
  }

  async create(user) {
    const result = await this.manager.controllers.api.post('/cts', {
      body: user
    });
    this.users = [result.Item, ...this.users];
  }

  find(id) {
    return this.users.findIndex(user => user.cognitoRef === id);
  }

  replace(id, valore) {
    const temp = [...this.users];
    const idx = this.find(id);
    if (idx !== -1) {
      temp[idx] = valore;
      this.users = temp;
    }
  }

  async getTeams(id) {
    return await this.manager.controllers.api.get(`/cts/${id}/teams`);
  }
  
  async update(id, user) {
    delete user.cognitoRef;
    const result = await this.manager.controllers.api.put(`/cts/${id}`, {
      body: user
    });
    this.replace(id, result.Item);
  }
}

export default decorate(CTS, {
  users: observable,
  map: computed,
  fetch: action,
  delete: action,
  update: action,
  replace: action
});