import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Space, Alert } from 'antd';
import Title from 'antd/lib/typography/Title';
import { navigate, useLocation } from '@reach/router';
import querystring from 'querystring';

import cm from '../../controllers';

const Forgot = ({showError, clearError}) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      const {email} = querystring.parse(location.search.slice(1));
      if (email) {
        form.setFieldsValue({
          email
        });
      }
    }
  }, [location.search])

  return <Form form={form} onFinish={async (values) => {
    try {
      clearError();
      setLoading(true);
      await cm.controllers.auth.forgotPassword(values.email);
      navigate(`/auth/reset-password?email=${values.email}`)
    } catch (e) {
      console.dir(e);
      switch (e.code) {
        case 'UserNotFoundException':
          showError('Email sconosciuta');
          break;
        default:
          showError('Errore sconosciuto');
          break;
      }
    } finally {
      setLoading(false)
    }
  }}>
    <Title level={2} style={{textAlign: 'center'}}>Recupera Password</Title>
    
    <Form.Item label="Email" name="email" rules={[
      {required: true, message: 'Campo necessario'},
      {type: 'email', message: 'Email non valida'}
    ]} >
      <Input type="email" />
    </Form.Item>
    <Form.Item style={{textAlign: 'right'}}>
      <Space>
        <Button type="link" onClick={() => navigate('/auth/login')}>
          Torna al login
        </Button>
        <Button type="primary" htmlType="submit" loading={loading}>
          Invia richiesta
        </Button>
      </Space>

    </Form.Item>

  </Form>

}

export default Forgot;