import React, { useEffect, useCallback, forwardRef, useMemo, useRef, useState } from 'react';
import {Form, Input, Row, Col, Select, Transfer, message} from 'antd';
import cm from '../../controllers';
import { observer } from 'mobx-react';
import { reaction } from 'mobx';
import { TEAM_TYPE } from '../../controllers/Teams';
import { LoadingOutlined } from '@ant-design/icons';

const EquipaggioSelector = observer(({value, onChange,  error}) => {
  const [initial, setInitial] = useState(value || []);
  const [disabled, setDisabled] = useState(cm.controllers.teams.list
    .filter(item => value && item.id !== value.id)
    .map(item => item.equipaggio)
    .reduce((arr, item) => arr.concat(item), []));

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setInitial(value || []);
  }, [value])

  reaction(
    () => cm.controllers.teams.list,
    () => {
      const dis = cm.controllers.teams.list
        .map(item => item.equipaggio)
        .reduce((arr, item) => arr.concat(item), []);
      setDisabled(dis)
    }
  )
  
  const handleChange = useCallback((value) => {
    if (value.length < 5) {
      onChange(value)
    }
  },[])

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        await cm.controllers.cts.fetch();
      } catch (e) {
        message.error('Impossibile caricare la lista CTS');
      } finally {
        setLoading(false)
      }
    }; 

    if (!cm.controllers.cts.fetched) {
      load();
    }     
  }, [])

 
  const users = cm.controllers.cts.users.slice().map(item => {
    return initial.indexOf(item.cognitoRef) !== -1 ||
      disabled.indexOf(item.cognitoRef) === -1
        ? {
          label: `${item.nome} ${item.cognome}`,
          key: item.cognitoRef,
          value: item.cognitoRef
        }
        : undefined
  }).filter(item => item);


  return loading ? <LoadingOutlined /> : <Select
    showSearch
    value={value}
    onChange={handleChange}
    mode="multiple"
    options={users}
    filterOption={(input, option) => { 
      return `${option.label}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }}
  />
});


const CreateTeamForm = (props) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        await cm.controllers.cts.fetch();
      } catch (e) {
        message.error('Impossibile caricare CTS')
      } finally {
        setLoading(false)
      }
    }

    if (!cm.controllers.cts.fetched) {
      load()
    }
  }, [])

  return <Form
    form={props.form}
    labelAlign="left"
    labelCol={{lg: 24}}
    wrapperCol={{lg: 24}}
    onFinish={async (values) => {
      try {
        props.setLoading(true);
        if (values.id) {
          const id = values.id;
          delete values.id;
          await cm.controllers.teams.update(id, values);
        } else {
          await cm.controllers.teams.create(values);
        }
        if (props.onComplete) {
          props.onComplete();
        }
      } catch (e) {
        message.error('Errore durante la creazione del team')
      } finally {
        props.setLoading(false);
      }
    }}
  >
  <Form.Item
      name="id"
      hidden
      style={{height: '0px'}}
    >
      <Input type="hidden" />          
    </Form.Item>
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          name="codice"
          label="Codice team"
          hasFeedback
          rules={[
            {required: true, message: 'Campo necessario'}
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="tipo"
          hasFeedback
          label="Tipologia"
          rules={[
            {required: true, message: 'Campo necessario'}
          ]}
        >
          <Select>
            {Object.keys(TEAM_TYPE).map(key => <Select.Option key={key} value={TEAM_TYPE[key]}>
              {key}
            </Select.Option>)} 
          </Select>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="equipaggio"
          label="Equipaggio"
          hasFeedback
          rules={[
            {validator: (_,value) => {
              if (value.length < 1) return Promise.reject('Almeno una persona per equipaggio');
              if (value.length > 4) return Promise.reject('Massimo 4 persone per equipaggio');
              return Promise.resolve();
            }}
          ]}

        >
        <EquipaggioSelector loading={loading}/>
        </Form.Item>
      </Col>
    </Row>
  </Form>
}

export default CreateTeamForm;