import React from 'react';
import ReactDOM from 'react-dom';

import 'antd/dist/antd.less';
import './style.css'
import {unregister} from './serviceWorker';
import App from './App';
import Amplify from 'aws-amplify';
import config from './config';

Amplify.configure(config.amplify);


ReactDOM.render(
  <App />,
  document.getElementById('root')
)

unregister();