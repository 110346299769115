import { Button, Card, message, Popconfirm, Space, Table, Tag } from 'antd';
import { observer } from 'mobx-react';
import { reaction } from 'mobx';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import useTableSearch from '../../hooks/useTableSearch';
import { DeleteOutlined, EditTwoTone, PlusCircleOutlined } from '@ant-design/icons';

import cm from '../../controllers'
import useModalForm from '../../hooks/useModalForm';
import { TEAM_COLORS, TEAM_TYPE, TEAM_TYPE_ARRAY } from '../../controllers/Teams';
import CreateTeamForm from '../Forms/CreateTeam';
import useDrawer from '../../hooks/useDrawer';
import UserDetail from '../cts/detail';


const populateEquipaggio = async () => {
  const t = _.cloneDeep(cm.controllers.teams.list);
  for (let i = 0 ; i < t.length; i++) {
    for (let j = 0; j < t[i].equipaggio.length; j++) {
      const user = await cm.controllers.cts.get(t[i].equipaggio[j]);
      t[i].equipaggio[j] = user;
    }
  }
  return t;
}

const SingleUserTag = (props) => <Tag>
  
</Tag>

const TeamsTable = observer(({loading, close}) => {
  const [teams, setTeams] = useState(null);
  const [customLoading, setCustomLoading] = useState(false);
  const [Drawer, open] = useDrawer();
  
  const [{show}, Modal] = useModalForm(CreateTeamForm);
  
  const codiceSearch = useTableSearch('codice');
  const equipaggioSearch = useTableSearch('equipaggio', {
    hasRender: true,
    searchFunction: (value, record) => {
      return record.equipaggio
        .map(persona => `${persona.nome} ${persona.cognome}`.toLowerCase().indexOf(value.toLowerCase()))
        .reduce((pre, curr) => pre || curr !== -1, false);
    }
  });


  useEffect(() => {
    let r;
    reaction(
      () => cm.controllers.teams.list,
      async (list, reaction) => {   
        r = reaction;
        setCustomLoading(true);
        await cm.controllers.cts.fetch();
        setTeams(await populateEquipaggio());
        setCustomLoading(false);
      },
      {fireImmediately: true}
    );
    return () => r.dispose();
  }, []);


 


  const onDelete = useCallback(async (id) => {
    try {
      setCustomLoading(true)
      await cm.controllers.teams.delete(id)
    } catch (e) {
      message.error('Impossibile eliminare il team')
    } finally {
      setCustomLoading(false)
    }
  }, [])

  const columns = useMemo(() => [
    {
      title: 'Codice equipaggio',
      key: 'codice',
      dataIndex: 'codice',
      sorter: {
        compare: (a, b) => a.codice > b.codice ? -1 : 1
      },
      ...codiceSearch
   }, {
      title: 'Tipologia',
      key: 'tipo',
      dataIndex: 'tipo',
      render: (item) => {
        return <Tag style={{...TEAM_COLORS[TEAM_TYPE_ARRAY[item]]}}>{TEAM_TYPE_ARRAY[item]}</Tag>;
      },
      sorter: {
        compare: (a, b) => {
          return a.tipo < b.tipo ? -1 : 1;
        }
      },
      filters: _.keys(TEAM_TYPE).map(key => ({
        text: key,
        value: TEAM_TYPE[key]
      })),
      onFilter: (value, record) => _.castArray(value).indexOf(record.tipo) !== -1
  }, {
    title: 'Equipaggio',
      key: 'equipaggio',
      dataIndex: 'equipaggio',
      render:  (team) => team.filter(item => item).map(utente => {
        if (!utente) return <div></div>
        return <Tag 
          onClick={() => open('Dettagli utente', UserDetail, {
            id: utente.cognitoRef
          })} 
          key={utente.cognitoRef}
          style={{cursor: 'pointer'}}
        >
          {utente.nome} {utente.cognome}
        </Tag>
      }),
      ...equipaggioSearch
  }, {
    title: '',
      key: 'actions',
      render: (item) => (
        <div style={{textAlign: 'right'}} >
          <Space>
            <Button
              icon={<EditTwoTone />}
              onClick={() => show({
                ...item,
                equipaggio: item.equipaggio.map(item => item.cognitoRef)
              }, `Modifica equipaggio ${item.codice}`)}
            >Modifica</Button>
            <Popconfirm
              placement="topRight"
              title={'Sei sicuro? Questa operazione non è reversibile.'}
              onConfirm={() => {
                onDelete(item.id)
              }}
              okText="Sicuro"
              cancelText="No"
              okType="danger"
            ><Button danger  icon={
              <DeleteOutlined />
            }>Elimina</Button>
            </Popconfirm>
          </Space>
        </div>
      )
  }], []);

  return <Card title="Gestione Teams" id="teams" extra={
    <Button onClick={() => {
      show({}, 'Crea team')
    }} type="primary" icon={<PlusCircleOutlined />} >Crea team</Button>
  }>
      <Table
        loading={teams === null || customLoading}
        dataSource={teams}
        columns={columns}
        rowKey={record => record.id}
      />    
      {Modal}
      <Drawer close={close}/>
    </Card>

});


const GestioneTeams = () => {

  reaction(() => cm.controllers.eventi.current, async () => {
    if (cm.controllers.eventi.current && cm.controllers.teams.fetched === false) {
      try {
        await cm.controllers.teams.fetch();
      } catch (e) {
        message.error('Impossibile caricare i team')
      }
    }
  })


  return <TeamsTable/>
}

export default GestioneTeams;