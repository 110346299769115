import React, { useMemo } from 'react';
import {observer} from 'mobx-react';
import { Space, Dropdown, Menu, Avatar } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';


import cm from '../../controllers';
import { navigate } from '@reach/router';

const ColorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];

const User = observer(() => {
  const color = useMemo(() => {
    return ColorList[cm.controllers.auth.user.attributes.email.length % ColorList.length]
  }, [cm.controllers.auth.user.attributes.email]);

  return <Dropdown
    trigger={['click']}
    placement="bottomRight"
    arrow
    overlay={
      <Menu>
        <Menu.Item icon={<LogoutOutlined />} onClick={async () => {
          navigate('/auth/login');
          await cm.controllers.auth.logout()
        }}> Esci </Menu.Item>
      </Menu>
    }
    >
    <Space style={{cursor: "pointer"}}>
      <Avatar style={{background: color, color: 'black'}}>
        {cm.controllers.auth.user.attributes.email.charAt(0).toUpperCase()}
      </Avatar>
      {cm.controllers.auth.user.attributes.email}
    </Space>
  </Dropdown>
});

export default User;