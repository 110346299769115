import { Button, Result } from 'antd';
import React from 'react';
import styled from 'styled-components';

import cm from '../controllers';

const Container = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  .ant-result-title {
    color: white !important;
  }
  .ant-result-subtitle {
    color: white !important;
  }
`;

const Unauthorized = (props) => <Container>
  <Result
    status="403"
    title="Accesso negato"
    subTitle="Non hai i permessi necessari per accedere"
    extra={<Button onClick={async () => {
      await cm.controllers.auth.logout();
      window.location='/'
    
    }} type="primary">Esci</Button>}
  />
</Container>

export default Unauthorized;