import { ArrowUpOutlined, CloseOutlined } from '@ant-design/icons';
import { Badge, Button, Card, Typography } from 'antd';
import { observer } from 'mobx-react';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import ChannelList from './channel-list';

import cm from '../../controllers';

const ChatContainer = styled.div`
  position: absolute;
  width: 800px;
  height: 800px;
  background: white;
  bottom: 0;
  right: 0;
  z-index: 888;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0,0,0,.3);
  transform: scale(${props => props.visible ? '1' : '0'});
  transform-origin: bottom right;
  transition: transform 300ms ease-in-out;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const CustomCard = styled(Card)`
  .ant-card-body {
    height: 100%;
    padding: 0;
  }
`;

const Chat = () => {
  
  const setOpen = useCallback((value) => {
    cm.controllers.chat.open = value
  }, []);

  return <CustomCard>
    <div style={{
      position: 'relative',
      height: '100%'
    }}>
      <div>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '24px'
        }}>
          <Typography.Title style={{margin: 0}} level={3}>Chat</Typography.Title>
          <Button onClick={() => setOpen(true)} icon={<ArrowUpOutlined />}></Button>
        </div>
        <h4 style={{textAlign: "center"}}>
          Ci sono <Badge 
            style={{pointerEvents: 'none',color: 'white' }} 
            showZero={true}
            count={
              Array.from(cm.controllers.chat.channels.keys())
                .reduce((total, channel) => total + cm.controllers.chat.channels.get(channel).unread, 0)
            } 
          /> messaggi non letti
        </h4>
      </div>
      
      <ChatContainer visible={cm.controllers.chat.open}>
        <header style={{
          padding: '15px 20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: '1px solid #d7d7d7'
        }}>
          <Typography.Title style={{margin: 0}} level={3}>Chat</Typography.Title>
          <Button type="text" icon={<CloseOutlined />} onClick={() => setOpen(false)}></Button>
        </header>
        <ChannelList />
      </ChatContainer>
    </div>
  </CustomCard>
};

export default observer(Chat);
