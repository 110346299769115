import Auth from './Auth';
import Config from './Config';
import API from './Api';
import config from '../config';
import CTS from './CTS';
import ControllerManager from '../utils/ControllerManager';
import Eventi from './Eventi';
import Tappe from './Tappe';
import Teams from './Teams';
import Segnalazioni from './Segnalazioni';
import Websocket from './Websocket';
import Chat from './Chat';

const controllerManager =  new ControllerManager([{
  Class: Config,
  options: {
    config
  }
}, {
  Class: Auth
}, {
  Class: API
}, {
  Class: CTS
}, {
  Class: Eventi
}, {
  Class: Tappe
}, {
  Class: Teams
}, {
  Class: Segnalazioni
}, {
  Class: Websocket
}, {
  Class: Chat
}]);

controllerManager.controllers.api.addHook('all', async (path, opts) => {
  if (!opts.headers) opts.headers = {};

  const session = await controllerManager.controllers.auth.getCurrentSession();


  opts.headers.Authorization = `Bearer ${session.accessToken.jwtToken}`;

  return {path, opts};
});

export default controllerManager;