import React from 'react';
import { Drawer } from 'antd';

const useDrawer = () => {
  const [state, setState] = React.useState({
    title: null,
    Component: null,
    props: {}
  });

  const open = React.useCallback((
    title,
    Component,
    props
  ) => { 
    setState({
      title,
      Component,
      props
    })
  }, []);

  const close = React.useCallback(() => {
    setState({
      title: null,
      Component: null,
      props: {}
    })
  }, []);

  const DrawerComponent = (props = {}) => {
    const {Component} = state;
    return (
      <Drawer width={props.width || '50%'} visible={!!state.Component} onClose={close}>
        {state.title ? <h2>{state.title}</h2> : ''}
        {
          state.Component
            ?  <Component {...state.props} close={close} closeAll={() => {
              close();
              if (props.close) {
                props.close();
              }
            }} />
            : null
        }
      </Drawer>
    )
  };

  return [DrawerComponent, open, close];

}

export default useDrawer;